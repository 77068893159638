import {
    GET_APPLICATION_TUTORIAL_REQUESTED,
    GET_APPLICATION_TUTORIAL_SUCCESS,
    GET_APPLICATION_TUTORIAL_FAILURE,
} from "../../actions/tutorial";

import {LOGOUT_SUCCESS} from "../../actions/account";

const initialState = {
    // Fetch list of application tutorial - api state
    // Dictionary of currently fetching tutorials, by application uuid. example: { "app1": true }
    isFetchingByApplicationUuid: {},
    // Error message
    fetchTutorialErrorMessage: null,
}

const tutorial = (state = initialState, action) => {
    switch (action.type) {
        // Get application tutorial api
        case GET_APPLICATION_TUTORIAL_REQUESTED:
            return {
                ...state,
                isFetchingByApplicationUuid: {
                    ...state.isFetchingByApplicationUuid,
                    [action.applicationUuid]: true
                },
            }

        case GET_APPLICATION_TUTORIAL_SUCCESS:
            return {
                ...state,
                isFetchingByApplicationUuid: {
                    ...state.isFetchingByApplicationUuid,
                    [action.applicationUuid]: false
                },
                fetchTutorialErrorMessage: null
            }

        case GET_APPLICATION_TUTORIAL_FAILURE:
            return {
                ...state,
                isFetchingByApplicationUuid: {
                    ...state.isFetchingByApplicationUuid,
                    [action.applicationUuid]: false
                },
                fetchTutorialErrorMessage: action.message
            }


        case LOGOUT_SUCCESS:
            return {
                ...state,
                ...initialState
            }

        default:
            return state
    }
}

export default tutorial
