import {
    GET_DEPLOYMENT_STATS_SUCCESS
} from "../../actions/deployments";
import {LOGOUT_SUCCESS} from "../../actions/account";

const initialState = {
    // Deployment stats by deployment uuid, example: { "dep1": { "cpu_avg": 0.5, "mem_avg": 256 } }
    byDeploymentUuid: {},
}

const deploymentStats = (state = initialState, action) => {
    switch (action.type) {

        case GET_DEPLOYMENT_STATS_SUCCESS:
            return {
                ...state,
                byDeploymentUuid: {
                    ...state.byDeploymentUuid,
                    [action.deploymentUuid]: action.stats
                }
            }

        case LOGOUT_SUCCESS:
            return {
                ...state,
                ...initialState
            }

        default:
            return state
    }
}

export default deploymentStats
