import { combineReducers } from 'redux';
import applicationStats from "./applicationStatsScheduler";
import applicationImages from "./applicationImagesScheduler";
import deploymentStats from "./deploymentStatsScheduler";
import deploymentInstances from "./deploymentInstancesScheduler";
import instanceLogs from "./instanceLogsScheduler"
import matchmakingQueueStats from "./matchmakingQueueStatsScheduler";

const schedulerReducer = (history) => combineReducers({
    applicationStats,
    applicationImages,
    deploymentStats,
    deploymentInstances,
    instanceLogs,
    matchmakingQueueStats,
})

export default schedulerReducer
