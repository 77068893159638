import api from '../utils/api';
import {addAlert} from "./alerts";
import {push} from "connected-react-router";


// Get application configurations actions
export const GET_APPLICATION_CONFIGURATIONS_REQUESTED = 'GET_APPLICATION_CONFIGURATIONS_REQUESTED';
const getApplicationConfigurationsRequest = (applicationUuid) => {
    return { type: GET_APPLICATION_CONFIGURATIONS_REQUESTED, applicationUuid }
};

export const GET_APPLICATION_CONFIGURATIONS_SUCCESS = 'GET_APPLICATION_CONFIGURATIONS_SUCCESS';
const getApplicationConfigurationsSuccess = (applicationUuid, configurations) => {
    return { type: GET_APPLICATION_CONFIGURATIONS_SUCCESS, applicationUuid, configurations }
};

export const GET_APPLICATION_CONFIGURATIONS_FAILURE = 'GET_APPLICATION_CONFIGURATIONS_FAILURE';
const getApplicationConfigurationsFailure = (applicationUuid, message) => {
    return { type: GET_APPLICATION_CONFIGURATIONS_FAILURE, applicationUuid, message }
};

export function getApplicationConfigurations(applicationUuid){
    return (dispatch) => {
        dispatch(getApplicationConfigurationsRequest(applicationUuid));

        api.get('/api/applications/' + applicationUuid + '/configurations')
            .then(({ status, data }) => {
                    if(status !== 200) {
                        dispatch(getApplicationConfigurationsFailure(applicationUuid, data.message))
                    }
                    else {
                        dispatch(getApplicationConfigurationsSuccess(applicationUuid, data.configurations))
                    }
                },
                err => dispatch(getApplicationConfigurationsFailure(applicationUuid, err.message)));
    }
}

// Create new configuration actions
export const CREATE_CONFIGURATION_REQUESTED = 'CREATE_CONFIGURATION_REQUESTED';
const createConfigurationRequest = (applicationUuid, configuration) => {
    return { type: CREATE_CONFIGURATION_REQUESTED, applicationUuid, configuration  }
};

export const CREATE_CONFIGURATION_SUCCESS = 'CREATE_CONFIGURATION_SUCCESS';
const createConfigurationSuccess = (applicationUuid, configuration) => {
    return { type: CREATE_CONFIGURATION_SUCCESS, applicationUuid, configuration }
};

export const CREATE_CONFIGURATION_FAILURE = 'CREATE_CONFIGURATION_FAILURE';
const createConfigurationFailure = (applicationUuid, message, errors) => {
    return { type: CREATE_CONFIGURATION_FAILURE, applicationUuid, message, errors }
};

export function createConfiguration(applicationUuid, minInstances, maxInstances, autoscalingMetric, autoscalingThresholdValue, autoscalingCooldownSeconds, ports){
    return (dispatch) => {
        let newConfiguration = {
            min_instances: minInstances,
            max_instances: maxInstances,
            autoscaling_metric: autoscalingMetric,
            autoscaling_threshold_value: autoscalingThresholdValue,
            autoscaling_cooldown_seconds: autoscalingCooldownSeconds,
            ports: ports
        };

        dispatch(createConfigurationRequest(applicationUuid, newConfiguration));

        api.post('/api/applications/' + applicationUuid + '/configuration', newConfiguration, true)
            .then(({ status, data }) => {
                    if(status !== 200) {
                        dispatch(createConfigurationFailure(applicationUuid, data.message, data.errors))
                    }
                    else {
                        dispatch(createConfigurationSuccess(applicationUuid, data.configuration))
                        dispatch(push( '/dashboard/applications/' + applicationUuid + '/configurations/' + data.configuration.uuid))
                        dispatch(addAlert("Configuration " + data.configuration.uuid.substr(0,8) + " created!", "success", true, 5000))
                    }
                },
                err => dispatch(createConfigurationFailure(applicationUuid, err.message)));
    }
}
