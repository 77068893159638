import {useEffect} from 'react';

export default function useEventListener(eventType, eventListener) {
    useEffect(
        () => {
            // Set up
            window.addEventListener(eventType, eventListener);

            // Clean up
            return () => {
                window.removeEventListener(eventType, eventListener);
            };
        },
        [eventType, eventListener]
    );
}
