import {
    GET_APPLICATION_STATS_SUCCESS,
} from "../../actions/applications";
import {LOGOUT_SUCCESS} from "../../actions/account";

const initialState = {
    // Application stats by application uuid, example: { "app1": {"cpu_avg": 0.5, "mem_avg": 256} }
    byApplicationUuid: {},
}

const applicationStats = (state = initialState, action) => {
    switch (action.type) {
        case GET_APPLICATION_STATS_SUCCESS:
            return {
                ...state,
                byApplicationUuid: {
                    ...state.byApplicationUuid,
                    [action.applicationUuid]: action.stats
                }
            }

        case LOGOUT_SUCCESS:
            return {
                ...state,
                ...initialState
            }

        default:
            return state
    }
}

export default applicationStats
