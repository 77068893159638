import React from "react";
import {connect} from "react-redux";

const mapStateToProps = function(state, ownProps){
    const instances = state.data.instances;

    return {
        deployment: instances.byUuid ? instances.byUuid[ownProps.match.params.instanceUuid] : null
    }
};

function InstanceBreadcrumb({ instance }){
    return (<span>{instance ? instance.label : "Instance"}</span>)
}

export default connect(
    mapStateToProps
)(InstanceBreadcrumb);
