import {
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE,
    REGISTER_SUCCESS,
    GET_USER_SUCCESS,
} from "../../actions/account";
import {getAuthToken} from "../../utils/storage";

const savedAuthToken = getAuthToken();

const initialState = {
    user: undefined,
    authToken: savedAuthToken,
    isLoggedIn: savedAuthToken !== null,
}

const login = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                authToken: action.authToken,
                user: action.user
            }
        case LOGOUT_SUCCESS:
            return {
                ...state,
                isLoggedIn: false,
                authToken: null,
                user: null,
            }
        case GET_USER_SUCCESS:
            return {
                ...state,
                user: action.user
            }
        default:
            return state
    }
}

export default login