import api from '../utils/api';

// Get api tokens actions
export const GET_API_TOKENS_REQUESTED = 'GET_API_TOKENS_REQUESTED';
const getApiTokensRequest = () => {
    return { type: GET_API_TOKENS_REQUESTED }
};

export const GET_API_TOKENS_SUCCESS = 'GET_API_TOKENS_SUCCESS';
const getApiTokensSuccess = (tokens) => {
    return { type: GET_API_TOKENS_SUCCESS, tokens }
};

export const GET_API_TOKENS_FAILURE = 'GET_API_TOKENS_FAILURE';
const getApiTokensFailure = (message) => {
    return { type: GET_API_TOKENS_FAILURE, message }
};

export function getApiTokens(){
    return (dispatch) => {
        dispatch(getApiTokensRequest());

        api.get('/api/api_tokens')
            .then(({ status, data }) => {
                    if(status !== 200) {
                        dispatch(getApiTokensFailure(data.message))
                    }
                    else {
                        dispatch(getApiTokensSuccess(data.api_tokens))
                    }
                },
                err => dispatch(getApiTokensFailure(err.message)));
    }
}

// Create new api token action
export const CREATE_API_TOKEN_REQUESTED = 'CREATE_API_TOKEN_REQUESTED';
const createApiTokenRequest = (token) => {
    return { type: CREATE_API_TOKEN_REQUESTED, token }
};

export const CREATE_API_TOKEN_SUCCESS = 'CREATE_API_TOKEN_SUCCESS';
const createApiTokenSuccess = (token) => {
    return { type: CREATE_API_TOKEN_SUCCESS, token }
};

export const CREATE_API_TOKEN_FAILURE = 'CREATE_API_TOKEN_FAILURE';
const createApiTokenFailure = (token, message, errors) => {
    return { type: CREATE_API_TOKEN_FAILURE, token, message, errors }
};

export function createApiToken(label, applicationUuid){
    return (dispatch) => {
        let newToken = {
            "label": label
        }

        if(applicationUuid) {
            newToken["application_uuid"] = applicationUuid;
        }

        dispatch(createApiTokenRequest(newToken));

        api.post('/api/api_tokens', newToken, true)
            .then(({ status, data }) => {
                    if(status !== 200) {
                        dispatch(createApiTokenFailure(newToken, data.message, data.errors))
                    }
                    else {
                        dispatch(createApiTokenSuccess(data.api_token))
                    }
                },
                err => dispatch(createApiTokenFailure(err.message)));
    }
}

// Delete api token action
export const DELETE_API_TOKEN_REQUESTED = 'DELETE_API_TOKEN_REQUESTED';
const deleteApiTokenRequest = (token) => {
    return { type: DELETE_API_TOKEN_REQUESTED, token }
};

export const DELETE_API_TOKEN_SUCCESS = 'DELETE_API_TOKEN_SUCCESS';
const deleteApiTokenSuccess = (token) => {
    return { type: DELETE_API_TOKEN_SUCCESS, token }
};

export const DELETE_API_TOKEN_FAILURE = 'DELETE_API_TOKEN_FAILURE';
const deleteApiTokenFailure = (token, message, errors) => {
    return { type: DELETE_API_TOKEN_FAILURE, token, message, errors }
};

export function deleteApiToken(token){
    return (dispatch) => {
        dispatch(deleteApiTokenRequest(token));

        api.delete('/api/api_tokens/' + token.uuid)
            .then(({ status, data }) => {
                    if(status !== 204) {
                        dispatch(deleteApiTokenFailure(token, data.message, data.errors))
                    }
                    else {
                        dispatch(deleteApiTokenSuccess(token))
                    }
                },
                err => dispatch(deleteApiTokenFailure(token, err.message)));
    }
}
