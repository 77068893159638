export function checkMobileLayout(){
    // Check if window width is small if so, add body-small class.
    if(typeof window === 'undefined'){
        return;  // Server-side rendering
    }

    if (window.outerWidth < 769) {
        document.body.classList.add("body-small");
    } else {
        document.body.classList.remove("body-small");
    }
}

export function getScrollPositionY() {
    if(typeof window === 'undefined'){
        return 0; // Server-side rendering
    }

    return window.pageYOffset || document.documentElement.scrollTop;
}

export function toggleMiniNavBar(isMini) {
    if (isMini) {
        document.body.classList.add("mini-navbar");
    } else {
        document.body.classList.remove("mini-navbar");
    }
}