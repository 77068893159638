import {
    GET_MATCHMAKING_QUEUE_STATS_SUCCESS
} from "../../actions/matchmaking";
import {LOGOUT_SUCCESS} from "../../actions/account";

const initialState = {
    // Matchmaking queue stats by queue uuid, example: { "que1": { "num_pending_tickets": 15, "avg_wait_time_seconds": 10.2 } }
    byQueueUuid: {},
}

const matchmakingQueueStats = (state = initialState, action) => {
    switch (action.type) {

        case GET_MATCHMAKING_QUEUE_STATS_SUCCESS:
            return {
                ...state,
                byQueueUuid: {
                    ...state.byQueueUuid,
                    [action.queueUuid]: action.stats
                }
            }

        case LOGOUT_SUCCESS:
            return {
                ...state,
                ...initialState
            }

        default:
            return state
    }
}

export default matchmakingQueueStats
