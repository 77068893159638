import {
    LOGIN_REQUESTED,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT_REQUESTED,
    REGISTER_FAILURE,
    REGISTER_REQUESTED,
    REGISTER_SUCCESS,
    GET_USER_REQUESTED,
    GET_USER_SUCCESS,
    GET_USER_FAILURE
} from "../../actions/account";

const initialState = {
    isLoginRequested: false, // true when login request is sent
    isRegisterRequested: false, // true when register request is sent
    isFetching: false, // true when logged in but fetching user information

    message: null, // error message
    errors: null, // potential form errors
}

const login = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_REQUESTED:
            return {
                ...state,
                isLoginRequested: true,
                message: null,
                errors: null
            }
        case LOGIN_FAILURE:
            return {
                ...state,
                isLoginRequested: false,
                message: action.message,
                errors: action.errors
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoginRequested: false,
                message: null,
                errors: null,
            }

        case LOGOUT_REQUESTED:
            return {
                ...state,
                message: null,
                errors: null,
            }
        case REGISTER_REQUESTED:
            return {
                ...state,
                isRegisterRequested: true,
                message: null,
                errors: null,
            }
        case REGISTER_FAILURE:
            return {
                ...state,
                isRegisterRequested: false,
                message: action.message,
                errors: action.errors,
            }
        case REGISTER_SUCCESS:
            return {
                ...state,
                isRegisterRequested: false,
                message: null,
                errors: null,
            }
        case GET_USER_REQUESTED:
            return {
                ...state,
                isFetching: true,
                message: null,
                errors: null,
            }
        case GET_USER_SUCCESS:
            return {
                ...state,
                isFetching: false,
            }
        case GET_USER_FAILURE:
            return {
                ...state,
                isFetching: false,
                message: action.message,
                errors: action.errors,
            }
        default:
            return state
    }
}

export default login