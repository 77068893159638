import {
    GET_API_TOKENS_SUCCESS,
    CREATE_API_TOKEN_SUCCESS,
    DELETE_API_TOKEN_SUCCESS,
} from "../../actions/tokens";

import {LOGOUT_SUCCESS} from '../../actions/account';

const initialState = {
    // List of all token uuids, example: ["tkn1", "tkn2"]
    // If null, tokens has never been fetched
    allUuids: null,

    // Tokens by token uuid, example: {"app1": { ... }, "app2": { ... }}
    byUuid: {},
}

const tokens = (state = initialState, action) => {
    switch (action.type) {
        case GET_API_TOKENS_SUCCESS:
            // Map list of tokens into a list of uuids: ["tkn1", "tkn2"]
            const tokenUuids = action.tokens.map((token, i) => token.uuid);

            // Reduce list of tokens into a dictionary by uuid: {"tkn1": { ... }, "tkn2": { ... }}
            const tokensByUuid = action.tokens.reduce((map, token) => { map[token.uuid] = token; return map; }, {});

            return {
                ...state,
                allUuids: tokenUuids,
                byUuid: tokensByUuid,
            }

        case CREATE_API_TOKEN_SUCCESS:
            return {
                ...state,
                allUuids: [
                    ...state.allUuids,
                    action.token.uuid
                ],
                byUuid: {
                    ...state.byUuid,
                    [action.token.uuid]: action.token
                },
            }

        case DELETE_API_TOKEN_SUCCESS:
            return {
                ...state,
                allUuids: state.allUuids.filter(uuid => uuid !== action.token.uuid),
                byUuid: Object.keys(state.byUuid)
                    .filter(uuid => uuid !== action.token.uuid)
                    .reduce((obj, uuid) => {
                        obj[uuid] = state.byUuid[uuid];
                        return obj;
                    }, {}),
            }

        case LOGOUT_SUCCESS:
            return {
                ...state,
                ...initialState
            }

        default:
            return state
    }
}

export default tokens