import {
    GET_APPLICATION_TUTORIAL_SUCCESS,
    SKIP_APPLICATION_TUTORIAL_SUCCESS,
} from "../../actions/tutorial";

import {LOGOUT_SUCCESS} from '../../actions/account';

const initialState = {
    // List of tutorial ids per application uuid. Example: {"app1": ["tut1", "tut2"]}
    byApplicationUuid: {},

    // tutorial by uuid. Example: {"tut1": { ... }, "tut2": { ... }}
    byUuid: {},
}

const tutorial = (state = initialState, action) => {
    switch (action.type) {
        case GET_APPLICATION_TUTORIAL_SUCCESS:
            return {
                ...state,
                byApplicationUuid: {
                    ...state.byApplicationUuid,
                    [action.applicationUuid]: action.tutorial.uuid,
                },
                byUuid: {
                    ...state.byUuid,
                    [action.tutorial.uuid]: action.tutorial,
                }
            }

        case SKIP_APPLICATION_TUTORIAL_SUCCESS:
            let tutorialUuid = state.byApplicationUuid[action.applicationUuid];
            let tutorial = state.byUuid[tutorialUuid];
            return {
                ...state,
                byUuid: {
                    ...state.byUuid,
                    [tutorialUuid]: {
                        ...tutorial,
                        tutorial_skipped: true,
                    }
                }
            }

        case LOGOUT_SUCCESS:
            return {
                ...state,
                ...initialState
            }

        default:
            return state
    }
}

export default tutorial