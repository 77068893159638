import {
    GET_DEPLOYMENT_INSTANCES_REQUEST_SCHEDULED,
    GET_DEPLOYMENT_INSTANCES_REQUESTED,
} from "../../actions/instances";
import {LOGOUT_SUCCESS} from "../../actions/account";

const initialState = {
    // Dictionary of scheduled deployment instance requests, by deployment uuid. Example: {"dep1": true}
    isScheduledByDeploymentUuid: {},
}

const deploymentInstances = (state = initialState, action) => {
    switch (action.type) {
        // Deployment list stats api
        case GET_DEPLOYMENT_INSTANCES_REQUEST_SCHEDULED:
            return {
                ...state,
                isScheduledByDeploymentUuid: {
                    ...state.isScheduledByDeploymentUuid,
                    [action.deploymentUuid]: true
                }
            }

        case GET_DEPLOYMENT_INSTANCES_REQUESTED:
            return {
                ...state,
                isScheduledByDeploymentUuid: {
                    ...state.isScheduledByDeploymentUuid,
                    [action.deploymentUuid]: false
                }
            }

        case LOGOUT_SUCCESS:
            return {
                ...state,
                ...initialState
            }

        default:
            return state
    }
}

export default deploymentInstances
