import {
    GET_APPLICATION_MATCHMAKING_QUEUES_REQUESTED,
    GET_APPLICATION_MATCHMAKING_QUEUES_SUCCESS,
    GET_APPLICATION_MATCHMAKING_QUEUES_FAILURE,
    GET_MATCHMAKING_QUEUE_REQUESTED,
    GET_MATCHMAKING_QUEUE_SUCCESS,
    GET_MATCHMAKING_QUEUE_FAILURE,
    CREATE_MATCHMAKING_QUEUE_REQUESTED,
    CREATE_MATCHMAKING_QUEUE_SUCCESS,
    CREATE_MATCHMAKING_QUEUE_FAILURE,
    UPDATE_MATCHMAKING_QUEUE_REQUESTED,
    UPDATE_MATCHMAKING_QUEUE_SUCCESS,
    UPDATE_MATCHMAKING_QUEUE_FAILURE,
    DELETE_MATCHMAKING_QUEUE_REQUESTED,
    DELETE_MATCHMAKING_QUEUE_SUCCESS,
    DELETE_MATCHMAKING_QUEUE_FAILURE,
} from "../../actions/matchmaking";

import {LOGOUT_SUCCESS} from "../../actions/account";

const initialState = {
    // Fetch list of application matchmaking queues - api state
    // Dictionary of currently fetching matchmaking queue lists, by application uuid. example: { "app1": true }
    isFetchingByApplicationUuid: {},
    // Error message
    fetchMatchmakingQueuesErrorMessage: null,

    // Fetch single matchmaking queue by uuid - api state
    // Dictionary of currently fetching individual queues, by queue uuid. Example: {"que1": true }
    isFetchingByQueueUuid: {},
    // Error message
    fetchMatchmakingQueueErrorMessage: null,

    // Create matchmaking queue - api state
    createMatchmakingQueueRequested: false,
    createMatchmakingQueueErrors: null,
    createMatchmakingQueueMessage: null,

    // Update matchmaking queue - api state
    updateMatchmakingQueueRequested: false,
    updateMatchmakingQueueErrors: null,
    updateMatchmakingQueueMessage: null,

    // Delete matchmaking queue - api state
    deleteMatchmakingQueueRequested: false,
    deleteMatchmakingQueueErrors: null,
    deleteMatchmakingQueueMessage: null,
}

const matchmakingQueues = (state = initialState, action) => {
    switch (action.type) {
        // List matchmaking queues api
        case GET_APPLICATION_MATCHMAKING_QUEUES_REQUESTED:
            return {
                ...state,
                isFetchingByApplicationUuid: {
                    ...state.isFetchingByApplicationUuid,
                    [action.applicationUuid]: true
                },
            }

        case GET_APPLICATION_MATCHMAKING_QUEUES_SUCCESS:
            return {
                ...state,
                isFetchingByApplicationUuid: {
                    ...state.isFetchingByApplicationUuid,
                    [action.applicationUuid]: false
                },
                fetchMatchmakingQueuesErrorMessage: null
            }

        case GET_APPLICATION_MATCHMAKING_QUEUES_FAILURE:
            return {
                ...state,
                isFetchingByApplicationUuid: {
                    ...state.isFetchingByApplicationUuid,
                    [action.applicationUuid]: false
                },
                fetchMatchmakingQueuesErrorMessage: action.message
            }

        // Get matchmaking queue api
        case GET_MATCHMAKING_QUEUE_REQUESTED:
            return {
                ...state,
                isFetchingByQueueUuid: {
                    ...state.isFetchingByQueueUuid,
                    [action.queueUuid]: true
                },
            }

        case GET_MATCHMAKING_QUEUE_SUCCESS:
            return {
                ...state,
                isFetchingByQueueUuid: {
                    ...state.isFetchingByQueueUuid,
                    [action.queueUuid]: false
                },
                fetchMatchmakingQueueErrorMessage: null
            }

        case GET_MATCHMAKING_QUEUE_FAILURE:
            return {
                ...state,
                isFetchingByQueueUuid: {
                    ...state.isFetchingByQueueUuid,
                    [action.queueUuid]: false
                },
                fetchMatchmakingQueueErrorMessage: action.message
            }

        // Create new matchmaking queue api
        case CREATE_MATCHMAKING_QUEUE_REQUESTED:
            return {
                ...state,
                createMatchmakingQueueRequested: true,
                createMatchmakingQueueMessage: null,
                createMatchmakingQueueErrors: null,
            }

        case CREATE_MATCHMAKING_QUEUE_SUCCESS:
            return {
                ...state,
                createMatchmakingQueueRequested: false,
                createMatchmakingQueueMessage: null,
                createMatchmakingQueueErrors: null,
            }

        case CREATE_MATCHMAKING_QUEUE_FAILURE:
            return {
                ...state,
                createMatchmakingQueueRequested: false,
                createMatchmakingQueueMessage: action.message,
                createMatchmakingQueueErrors: action.errors,
            }

        // Update existing matchmaking queue api
        case UPDATE_MATCHMAKING_QUEUE_REQUESTED:
            return {
                ...state,
                updateMatchmakingQueueRequested: true,
                updateMatchmakingQueueMessage: null,
                updateMatchmakingQueueErrors: null,
            }

        case UPDATE_MATCHMAKING_QUEUE_SUCCESS:
            return {
                ...state,
                updateMatchmakingQueueRequested: false,
                updateMatchmakingQueueMessage: null,
                updateMatchmakingQueueErrors: null,
            }

        case UPDATE_MATCHMAKING_QUEUE_FAILURE:
            return {
                ...state,
                updateMatchmakingQueueRequested: false,
                updateMatchmakingQueueMessage: action.message,
                updateMatchmakingQueueErrors: action.errors,
            }

        // Delete matchmaking queue api
        case DELETE_MATCHMAKING_QUEUE_REQUESTED:
            return {
                ...state,
                deleteMatchmakingQueueRequested: true,
                deleteMatchmakingQueueMessage: null,
                deleteMatchmakingQueueErrors: null,
            }

        case DELETE_MATCHMAKING_QUEUE_SUCCESS:
            return {
                ...state,
                deleteMatchmakingQueueRequested: false,
                deleteMatchmakingQueueMessage: null,
                deleteMatchmakingQueueErrors: null,
            }

        case DELETE_MATCHMAKING_QUEUE_FAILURE:
            return {
                ...state,
                deleteMatchmakingQueueRequested: false,
                deleteMatchmakingQueueMessage: action.message,
                deleteMatchmakingQueueErrors: action.errors,
            }

        case LOGOUT_SUCCESS:
            return {
                ...state,
                ...initialState
            }

        default:
            return state
    }
}

export default matchmakingQueues
