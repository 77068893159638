import {
    GET_APPLICATION_STATS_REQUEST_SCHEDULED,
    GET_APPLICATION_STATS_REQUESTED,
} from "../../actions/applications";

const initialState = {
    // Dictionary of scheduled application stats requests, by application uuid. Example: {"app1": true}
    isScheduledByApplicationUuid: {},
}

const applicationStatsScheduler = (state = initialState, action) => {
    switch (action.type) {
        case GET_APPLICATION_STATS_REQUEST_SCHEDULED:
            return {
                ...state,
                isScheduledByApplicationUuid: {
                    ...state.isScheduledByApplicationUuid,
                    [action.applicationUuid]: true
                }
            }
        case GET_APPLICATION_STATS_REQUESTED:
            return {
                ...state,
                isScheduledByApplicationUuid: {
                    ...state.isScheduledByApplicationUuid,
                    [action.applicationUuid]: false
                }
            }
        default:
            return state
    }
}

export default applicationStatsScheduler
