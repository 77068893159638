import {
    GET_INSTANCE_LOGS_SUCCESS
} from "../../actions/instances";
import {LOGOUT_SUCCESS} from "../../actions/account";

const initialState = {
    // Instance logs by instance uuid, example: { "ins1": ["line", "line2"] }
    byInstanceUuid: {},
}

const instanceLogs = (state = initialState, action) => {
    switch (action.type) {

        case GET_INSTANCE_LOGS_SUCCESS:
            return {
                ...state,
                byInstanceUuid: {
                    ...state.byInstanceUuid,
                    [action.instanceUuid]: action.logs
                }
            }

        case LOGOUT_SUCCESS:
            return {
                ...state,
                ...initialState
            }

        default:
            return state
    }
}

export default instanceLogs
