import { combineReducers } from 'redux';
import applications  from './applicationsApi';
import applicationStats from "./applicationStatsApi";
import deployments from "./deploymentsApi";
import deploymentStats from "./deploymentStatsApi"
import instances from "./instancesApi";
import instanceLogs from "./instanceLogsApi";
import configurations from "./configurationsApi";
import images from "./imagesApi";
import tutorial from "./tutorialApi";
import tokens from "./tokensApi";
import login from "./loginApi"
import beta from "./betaApi"
import matchmakingQueues from "./matchmakingQueuesApi";
import matchmakingQueueStats from "./matchmakingQueueStatsApi";

const apiReducer = (history) => combineReducers({
    applications,
    applicationStats,
    deployments,
    deploymentStats,
    instances,
    instanceLogs,
    configurations,
    images,
    tutorial,
    tokens,
    login,
    beta,
    matchmakingQueues,
    matchmakingQueueStats,
})

export default apiReducer
