import { v4 as uuidv4 } from 'uuid';

// Add alert
export const ADD_ALERT = 'ADD_ALERT';
function _addAlert (uuid, text, level, dismissable= true) {
    return {
        type: ADD_ALERT,
        uuid: uuid,
        text: text,
        level: level,
        dismissable: dismissable
    }
}

export function addAlert(text, level, dismissable= true, expireAfter = null) {
    return (dispatch) => {
        let uuid = uuidv4();

        dispatch(_addAlert(uuid, text, level, dismissable));

        // If auto remove is set, automatically remove it
        if(expireAfter !== null) {
            setTimeout(() => dispatch(removeAlert(uuid)), expireAfter);
        }
    };
}

// Remove alert
export const REMOVE_ALERT = 'REMOVE_ALERT';
function _removeAlert (uuid) {
    return { type: REMOVE_ALERT, uuid }
}

export function removeAlert(uuid) {
    return (dispatch) => {
        dispatch(_removeAlert(uuid));
    };
}
