import {
    GET_APPLICATION_IMAGES_REQUESTED,
    GET_APPLICATION_IMAGES_SUCCESS,
    GET_APPLICATION_IMAGES_FAILURE,
} from "../../actions/images";

import {LOGOUT_SUCCESS} from "../../actions/account";

const initialState = {
    // Fetch list of application images - api state
    // Dictionary of currently fetching image lists, by application uuid. example: { "app1": true }
    isFetchingByApplicationUuid: {},
    // Error message
    fetchImagesErrorMessage: null,
}

const images = (state = initialState, action) => {
    switch (action.type) {
        // List images api
        case GET_APPLICATION_IMAGES_REQUESTED:
            return {
                ...state,
                isFetchingByApplicationUuid: {
                    ...state.isFetchingByApplicationUuid,
                    [action.applicationUuid]: true
                },
            }

        case GET_APPLICATION_IMAGES_SUCCESS:
            return {
                ...state,
                isFetchingByApplicationUuid: {
                    ...state.isFetchingByApplicationUuid,
                    [action.applicationUuid]: false
                },
                fetchImagesErrorMessage: null
            }

        case GET_APPLICATION_IMAGES_FAILURE:
            return {
                ...state,
                isFetchingByApplicationUuid: {
                    ...state.isFetchingByApplicationUuid,
                    [action.applicationUuid]: false
                },
                fetchImagesErrorMessage: action.message
            }


        case LOGOUT_SUCCESS:
            return {
                ...state,
                ...initialState
            }

        default:
            return state
    }
}

export default images
