import React from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router'
import { Switch, } from 'react-router';
import routeConfig from "./constants/routeConfig";
import RouteWithSubRoutes from "./components/routes/RouteWithSubRoutes";
import useEventListener from "./hooks/useEventListener";
import { checkMobileLayout } from "./utils/layout";
import AlertList from "./containers/alerts/AlertList";

// Import styles
import './style';

// App router
const App = () => {
    // Add body-small class when window is resized
    useEventListener('resize', () => checkMobileLayout);

    // Re-evaluate on re-render
    checkMobileLayout();

    return (
        <div>
            <Switch>
                {routeConfig.map(function(route, i) {
                    return (<RouteWithSubRoutes key={i} {...route} />)
                })}
            </Switch>

            <AlertList />
        </div>
    )
}

export default App