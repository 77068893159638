import {
    GET_APPLICATION_MATCHMAKING_QUEUES_SUCCESS,
    GET_MATCHMAKING_QUEUE_SUCCESS,
    CREATE_MATCHMAKING_QUEUE_SUCCESS,
    UPDATE_MATCHMAKING_QUEUE_SUCCESS,
    DELETE_MATCHMAKING_QUEUE_SUCCESS,
} from "../../actions/matchmaking";

import {LOGOUT_SUCCESS} from '../../actions/account';

const initialState = {
    // List of matchmaking queue UUIDs per application uuid. Example: {"app1": ["que1", "que2"]}
    byApplicationUuid: {},

    // Matchmaking queue by uuid. Example: {"qu1": { ... }, "que2": { ... }}
    byUuid: {},
}

const matchmakingQueues = (state = initialState, action) => {
    switch (action.type) {
        case GET_APPLICATION_MATCHMAKING_QUEUES_SUCCESS:
            // Map list of queues into a list of uuids: ["que1", "que2"]
            let applicationMatchmakingQueueUuids = action.queues.map((queue, i) => queue.uuid);

            // Reduce list of queues into a dictionary by uuid: {"que1": { ... }, "que2": { ... }}
            let applicationMatchmakingQueueByUuid = action.queues.reduce((map, queue) => {
                map[queue.uuid] = queue;
                return map;
            }, {});

            return {
                ...state,
                byApplicationUuid: {
                    ...state.byApplicationUuid,
                    [action.applicationUuid]: applicationMatchmakingQueueUuids,
                },
                byUuid: {
                    ...state.byUuid,
                    ...applicationMatchmakingQueueByUuid,
                }
            }

        case GET_MATCHMAKING_QUEUE_SUCCESS:
            return {
                ...state,
                byUuid: {
                    ...state.byUuid,
                    ...{ [action.queue.uuid]: action.queue },
                }
            }

        case CREATE_MATCHMAKING_QUEUE_SUCCESS:
            return {
                ...state,
                byUuid: {
                    ...state.byUuid,
                    ...{ [action.queue.uuid]: action.queue },
                },
            }

        case UPDATE_MATCHMAKING_QUEUE_SUCCESS:
            return {
                ...state,
                byUuid: Object.keys(state.byUuid)
                    .reduce((obj, uuid) => {
                        if(uuid === action.queue.uuid)
                            obj[uuid] = action.queue;
                        else
                            obj[uuid] = state.byUuid[uuid];
                        return obj;
                    }, {}),
            }

        case DELETE_MATCHMAKING_QUEUE_SUCCESS:
            return {
                ...state,
                byApplicationUuid: {
                    ...state.byApplicationUuid,
                    [action.queue.application_uuid]: state.byApplicationUuid[action.queue.application_uuid]
                        ? state.byApplicationUuid[action.queue.application_uuid].filter(uuid => uuid !== action.queue.uuid)
                        : undefined
                },
                byUuid: Object.keys(state.byUuid)
                    .filter(uuid => uuid !== action.queue.uuid)
                    .reduce((obj, uuid) => {
                        obj[uuid] = state.byUuid[uuid];
                        return obj;
                    }, {}),
            }
        case LOGOUT_SUCCESS:
            return {
                ...state,
                ...initialState
            }

        default:
            return state
    }
}

export default matchmakingQueues
