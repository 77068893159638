import { push } from 'connected-react-router'
import {clearAuthToken, saveAuthToken} from "../utils/storage";
import api from "../utils/api";

// Login actions
export const LOGIN_REQUESTED = 'LOGIN_REQUESTED';
const loginRequest = () => {
    return { type: LOGIN_REQUESTED   }
};

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const loginSuccess = (authToken, user) => {
    return { type: LOGIN_SUCCESS, authToken, user }
};

export const LOGIN_FAILURE = 'LOGIN_FAILURE';
const loginFailure = (message, errors = null) => {
    return { type: LOGIN_FAILURE, message, errors }
};

function storeLoginInfo(authToken){
    saveAuthToken(authToken)
}

export function login(email, password, redirectTo = null){
    return (dispatch) => {
        dispatch(loginRequest());

        api.post('/user/login', { email, password })
            .then(({ status, data }) => {
                    if(status !== 200) {
                        dispatch(loginFailure(data.message, data.errors))
                    }
                    else {
                        storeLoginInfo(data.auth_token, data.user)
                        dispatch(loginSuccess(data.auth_token, data.user))
                        dispatch(push(redirectTo || '/dashboard'))
                    }
                },
                err => dispatch(loginFailure(err.message)));
    }
}

// Logout actions
export const LOGOUT_REQUESTED = 'LOGOUT_REQUESTED';
const logoutRequest = () => {
    return { type: LOGOUT_REQUESTED   }
};

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
const logoutSuccess = () => {
    return { type: LOGOUT_SUCCESS }
};

export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
const logoutFailure = () => {
    return { type: LOGOUT_FAILURE }
};


export function logout() {
    return (dispatch) => {
        dispatch(logoutRequest());

        api.post('/user/logout')
            .then(({ status, data }) => {
                    if(status !== 200) {
                        dispatch(logoutFailure(data.message))
                    }
                    else {
                        clearAuthToken()
                        dispatch(logoutSuccess())
                        dispatch(push('/login'))
                    }
                },
                err => dispatch(logoutFailure(err.message)));
    }
}

export function forceLogout(currentLocation) {
    return (dispatch) => {
        // Log out user without hitting log out endpoint
        // This method is used when e.g. auth token is expired or is invalid
        dispatch(logoutRequest());
        clearAuthToken()
        dispatch(logoutSuccess())

        // Redirect to login page, try to page user wanted to visit
        dispatch(push('/login', {prevPath: currentLocation.pathname}))
    }
}

// Get user actions
export const GET_USER_REQUESTED = 'GET_USER_REQUESTED';
const getUserRequest = () => {
    return { type: GET_USER_REQUESTED   }
};

export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
const getUserSuccess = (user) => {
    return { type: GET_USER_SUCCESS, user }
};

export const GET_USER_FAILURE = 'GET_USER_FAILURE';
const getUserFailure = (message, errors = null) => {
    return { type: GET_USER_FAILURE, message, errors }
};


export function getUser(currentLocation=null) {
    return (dispatch) => {
        dispatch(getUserRequest());

        api.get('/user/get')
            .then(({ status, data }) => {
                    if(status !== 200) {
                        if(status === 401){
                            // Auth token likely expired, redirect to login page
                            dispatch(forceLogout(currentLocation))
                        }
                        dispatch(getUserFailure(data.message, data.errors))
                    }
                    else {
                        dispatch(getUserSuccess(data.user))
                    }
                },
                err => dispatch(getUserFailure(err.message)));
    }
}


// Register actions
export const REGISTER_REQUESTED = 'REGISTER_REQUESTED';
const registerRequest = () => {
    return { type: REGISTER_REQUESTED   }
};

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
const registerSuccess = () => {
    return { type: REGISTER_SUCCESS }
};

export const REGISTER_FAILURE = 'REGISTER_FAILURE';
const registerFailure = (message, errors = null) => {
    return { type: REGISTER_FAILURE, message, errors }
};

export function register(email, password, invitationCode) {
    return (dispatch) => {
        dispatch(registerRequest());

        api.post('/user/create', { email: email, password: password, invitation_code: invitationCode })
            .then(({ status, data }) => {
                    if(status !== 200) {
                        dispatch(registerFailure(data.message, data.errors))
                    }
                    else {
                        dispatch(registerSuccess())
                        storeLoginInfo(data.auth_token, data.user)
                        dispatch(loginSuccess(data.auth_token, data.user))
                        dispatch(push( '/dashboard'))
                    }
                },
                err => dispatch(registerFailure(err.message)));
    }
}
