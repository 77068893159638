import React from "react";
import { Route } from 'react-router';
import PropTypes from "prop-types";

export default function RouteWithSubRoutes(route){
    return (
        <Route
            path={route.path}
            exact={route.exact}
            render={function(props) {
                return (<route.component { ...props } { ...route.componentProps } routes={ route.routes }/>)
            }}
        />
    )
}

RouteWithSubRoutes.propTypes = {
    path: PropTypes.string,
    exact: PropTypes.bool,
    componentProps: PropTypes.object,
    routes: PropTypes.array,
}
