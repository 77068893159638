import {
    GET_DEPLOYMENT_INSTANCES_SUCCESS,
    GET_INSTANCE_SUCCESS,
} from "../../actions/instances";

import {LOGOUT_SUCCESS} from '../../actions/account';

const initialState = {
    // List of instance uuids per deploymnet uuid. Example: {"dep1": ["ins1", "ins2"]}
    byDeploymentUuid: {},

    // Instance by uuid. Example: {"ins1": { ... }, "ins2": { ... }}
    byUuid: {},
}

const instances = (state = initialState, action) => {
    switch (action.type) {
        case GET_DEPLOYMENT_INSTANCES_SUCCESS:
            // Map list of instances into a list of uuids: ["ins1", "ins2"]
            let deploymentInstanceUuids = action.instances.map((instance, i) => instance.uuid);

            // Reduce list of instances into a dictionary by uuid: {"ins1": { ... }, "ins2": { ... }}
            let deploymentInstancesByUuid = action.instances.reduce((map, instance) => {
                map[instance.uuid] = instance;
                return map;
            }, {});

            return {
                ...state,
                byDeploymentUuid: {
                    ...state.byDeploymentUuid,
                    [action.deploymentUuid]: deploymentInstanceUuids,
                },
                byUuid: {
                    ...state.byUuid,
                    ...deploymentInstancesByUuid,
                }
            }

        case GET_INSTANCE_SUCCESS:
            return {
                ...state,
                byUuid: {
                    ...state.byUuid,
                    ...{ [action.instance.uuid]: action.instance },
                }
            }

        case LOGOUT_SUCCESS:
            return {
                ...state,
                ...initialState
            }

        default:
            return state
    }
}

export default instances
