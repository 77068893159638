import {
    GET_APPLICATION_STATS_REQUESTED,
    GET_APPLICATION_STATS_SUCCESS,
    GET_APPLICATION_STATS_FAILURE,
} from "../../actions/applications";
import {LOGOUT_SUCCESS} from "../../actions/account";

const initialState = {
    // Dictionary of currently fetching applicaiton stats, by application uuid. example: { "app1": true }
    isFetchingByApplicationUuid: {},
}

const applicationStats = (state = initialState, action) => {
    switch (action.type) {
        case GET_APPLICATION_STATS_REQUESTED:
            return {
                ...state,
                isFetchingByApplicationUuid: {
                    ...state.isFetchingByApplicationUuid,
                    [action.applicationUuid]: true
                }
            }

        case GET_APPLICATION_STATS_SUCCESS:
            return {
                ...state,
                isFetchingByApplicationUuid: {
                    ...state.isFetchingByApplicationUuid,
                    [action.applicationUuid]: false
                }
            }

        case GET_APPLICATION_STATS_FAILURE:
            return {
                ...state,
                isFetchingByApplicationUuid: {
                    ...state.isFetchingByApplicationUuid,
                    [action.applicationUuid]: false
                }
            }

        case LOGOUT_SUCCESS:
            return {
                ...state,
                ...initialState
            }

        default:
            return state
    }
}

export default applicationStats
