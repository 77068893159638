import {
    GET_MATCHMAKING_QUEUE_STATS_REQUESTED,
    GET_MATCHMAKING_QUEUE_STATS_SUCCESS,
    GET_MATCHMAKING_QUEUE_STATS_FAILURE,
} from "../../actions/matchmaking";
import {LOGOUT_SUCCESS} from "../../actions/account";

const initialState = {
    // Dictionary of currently fetching matchmaking queue stats, by queue uuid. Example: { "que1": true }
    isFetchingByQueueUuid: {},
}

const matchmakingQueueStats = (state = initialState, action) => {
    switch (action.type) {
        // Single matchmaking queue stats api
        case GET_MATCHMAKING_QUEUE_STATS_REQUESTED:
            return {
                ...state,
                isFetchingByQueueUuid: {
                    ...state.isFetchingByQueueUuid,
                    [action.queueUuid]: true
                }
            }

        case GET_MATCHMAKING_QUEUE_STATS_SUCCESS:
            return {
                ...state,
                isFetchingByQueueUuid: {
                    ...state.isFetchingByQueueUuid,
                    [action.queueUuid]: false
                }
            }

        case GET_MATCHMAKING_QUEUE_STATS_FAILURE:
            return {
                ...state,
                isFetchingByQueueUuid: {
                    ...state.isFetchingByQueueUuid,
                    [action.queueUuid]: false
                }
            }

        case LOGOUT_SUCCESS:
            return {
                ...state,
                ...initialState
            }

        default:
            return state
    }
}

export default matchmakingQueueStats
