import {
    GET_APPLICATION_CONFIGURATIONS_SUCCESS,
    CREATE_CONFIGURATION_SUCCESS,
} from "../../actions/configurations";

import {LOGOUT_SUCCESS} from '../../actions/account';

const initialState = {
    // List of deployments uuids per application uuid. Example: {"app1": ["cnf1", "cnf2"]}
    byApplicationUuid: {},

    // Configuration by uuid. Example: {"cnf1": { ... }, "cnf2": { ... }}
    byUuid: {},
}

const configurations = (state = initialState, action) => {
    switch (action.type) {
        case GET_APPLICATION_CONFIGURATIONS_SUCCESS:
            // Map list of configurations into a list of uuids: ["conf1", "conf2"]
            let applicationConfigurationUuids = action.configurations.map((configuration, i) => configuration.uuid);

            // Reduce list of configurations into a dictionary by uuid: {"conf1": { ... }, "conf2": { ... }}
            let applicationConfigurationByUuid = action.configurations.reduce((map, configuration) => {
                map[configuration.uuid] = configuration;
                return map;
            }, {});

            return {
                ...state,
                byApplicationUuid: {
                    ...state.byApplicationUuid,
                    [action.applicationUuid]: applicationConfigurationUuids,
                },
                byUuid: {
                    ...state.byUuid,
                    ...applicationConfigurationByUuid,
                }
            }

        case CREATE_CONFIGURATION_SUCCESS:
            return {
                ...state,
                byApplicationUuid: {
                    ...state.byApplicationUuid,
                    [action.applicationUuid]: [action.configuration.uuid, ...state.byApplicationUuid[action.applicationUuid]]
                },
                byUuid: {
                    ...state.byUuid,
                    ...{ [action.configuration.uuid]: action.configuration },
                },
            }

        case LOGOUT_SUCCESS:
            return {
                ...state,
                ...initialState
            }

        default:
            return state
    }
}

export default configurations
