import {
    GET_APPLICATIONS_SUCCESS,
    CREATE_APPLICATION_SUCCESS,
} from "../../actions/applications";

import {LOGOUT_SUCCESS} from '../../actions/account';

const initialState = {
    // List of all application uuids, example: ["app1", "app2"]
    // If null, applications has never been fetched
    allUuids: null,

    // Applications by application uuid, example: {"app1": { ... }, "app2": { ... }}
    byUuid: {},
}

const applications = (state = initialState, action) => {
    switch (action.type) {
        case GET_APPLICATIONS_SUCCESS:
            // Map list of applications into a list of uuids: ["app1", "app2"]
            const applicationUuids = action.applications.map((application, i) => application.uuid);

            // Reduce list of applications into a dictionary by uuid: {"app1": { ... }, "app2": { ... }}
            const applicationsByUuids = action.applications.reduce((map, application) => { map[application.uuid] = application; return map; }, {});

            return {
                ...state,
                allUuids: applicationUuids,
                byUuid: applicationsByUuids,
            }

        case CREATE_APPLICATION_SUCCESS:
            return {
                ...state,
                allUuids: [
                    ...state.allUuids,
                    action.application.uuid
                ],
                byUuid: {
                    ...state.byUuid,
                    [action.application.uuid]: action.application
                },
            }

        case LOGOUT_SUCCESS:
            return {
                ...state,
                ...initialState
            }

        default:
            return state
    }
}

export default applications