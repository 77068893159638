import {
    GET_MATCHMAKING_QUEUE_STATS_REQUEST_SCHEDULED,
    GET_MATCHMAKING_QUEUE_STATS_REQUESTED,
} from "../../actions/matchmaking";
import {LOGOUT_SUCCESS} from "../../actions/account";

const initialState = {
    // Dictionary of scheduled matchmaking queue stats requests, by queue uuid. Example: { "que1": true }
    isScheduledByQueueUuid: {},

}

const matchmakingQueueStats = (state = initialState, action) => {
    switch (action.type) {
        // Single matchmaking queue stats api
        case GET_MATCHMAKING_QUEUE_STATS_REQUEST_SCHEDULED:
            return {
                ...state,
                isScheduledByQueueUuid: {
                    ...state.isScheduledByQueueUuid,
                    [action.queueUuid]: true
                }
            }

        case GET_MATCHMAKING_QUEUE_STATS_REQUESTED:
            return {
                ...state,
                isScheduledByQueueUuid: {
                    ...state.isScheduledByQueueUuid,
                    [action.queueUuid]: false
                }
            }


        case LOGOUT_SUCCESS:
            return {
                ...state,
                ...initialState
            }

        default:
            return state
    }
}

export default matchmakingQueueStats
