import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import apiReducer from "./api";
import dataReducer from "./data";
import schedulerReducer from "./scheduler";
import utilReducer from "./util";

const rootReducer = (history) => combineReducers({
    router: connectRouter(history),
    api: apiReducer(history),
    data: dataReducer(history),
    scheduler: schedulerReducer(history),
    util: utilReducer(history),
})

export default rootReducer
