import api from '../utils/api';
import {push} from "connected-react-router";
import {setApplicationTutorialTemplateUuid} from "./tutorial";

// Get applications actions
export const GET_APPLICATIONS_REQUESTED = 'GET_APPLICATIONS_REQUESTED';
const getApplicationsRequest = () => {
    return { type: GET_APPLICATIONS_REQUESTED   }
};

export const GET_APPLICATIONS_SUCCESS = 'GET_APPLICATIONS_SUCCESS';
const getApplicationsSuccess = (applications) => {
    return { type: GET_APPLICATIONS_SUCCESS, applications }
};

export const GET_APPLICATIONS_FAILURE = 'GET_APPLICATIONS_FAILURE';
const getApplicationsFailure = (message) => {
    return { type: GET_APPLICATIONS_FAILURE, message }
};

export function getApplications(){
    return (dispatch) => {
        dispatch(getApplicationsRequest());

        api.get('/api/applications')
            .then(({ status, data }) => {
                    if(status !== 200) {
                        dispatch(getApplicationsFailure(data.message))
                    }
                    else {
                        dispatch(getApplicationsSuccess(data.applications))
                    }
                },
                err => dispatch(getApplicationsFailure(err.message)));
    }
}

// Get application stats actions
export const GET_APPLICATION_STATS_REQUESTED = 'GET_APPLICATION_STATS_REQUESTED';
const getApplicationStatsRequest = (applicationUuid) => {
    return { type: GET_APPLICATION_STATS_REQUESTED, applicationUuid }
};

export const GET_APPLICATION_STATS_SUCCESS = 'GET_APPLICATION_STATS_SUCCESS';
const getApplicationStatsSuccess = (applicationUuid, stats) => {
    return { type: GET_APPLICATION_STATS_SUCCESS, applicationUuid, stats }
};

export const GET_APPLICATION_STATS_FAILURE = 'GET_APPLICATION_STATS_FAILURE';
const getApplicationStatsFailure = (applicationUuid, message) => {
    return { type: GET_APPLICATION_STATS_FAILURE, applicationUuid, message }
};

// Application stats scheduler actions
export const GET_APPLICATION_STATS_REQUEST_SCHEDULED = 'GET_APPLICATION_STATS_REQUEST_SCHEDULED';
const scheduleGetApplicationStatsRequest = (applicationUuid) => {
    return { type: GET_APPLICATION_STATS_REQUEST_SCHEDULED, applicationUuid }
};


export function scheduleGetApplicationStats(applicationUuid, delay = 0){
    return (dispatch) => {
        dispatch(scheduleGetApplicationStatsRequest(applicationUuid));

        setTimeout(() => {
            dispatch(getApplicationStatsRequest(applicationUuid));

            api.get('/api/applications/' + applicationUuid + '/stats')
                .then(({status, data}) => {
                        if (status !== 200) {
                            dispatch(getApplicationStatsFailure(applicationUuid, data.message))
                        } else {
                            dispatch(getApplicationStatsSuccess(applicationUuid, data.stats))
                        }
                    },
                    err => dispatch(getApplicationStatsFailure(applicationUuid, err.message)));
        }, delay);
    }
}

// Create new application actions
export const CREATE_APPLICATION_REQUESTED = 'CREATE_APPLICATION_REQUESTED';
const createApplicationRequest = (application) => {
    return { type: CREATE_APPLICATION_REQUESTED, application }
};

export const CREATE_APPLICATION_SUCCESS = 'CREATE_APPLICATION_SUCCESS';
const createApplicationSuccess = (application) => {
    return { type: CREATE_APPLICATION_SUCCESS, application }
};

export const CREATE_APPLICATION_FAILURE = 'CREATE_APPLICATION_FAILURE';
const createApplicationFailure = (message, errors) => {
    return { type: CREATE_APPLICATION_FAILURE, message, errors }
};

export function createApplication(name, configuration, templateUuid){
    return (dispatch) => {
        let newApplication = { name, configuration };

        dispatch(createApplicationRequest(newApplication));

        api.post('/api/applications', newApplication, true)
            .then(({ status, data }) => {
                    if(status !== 200) {
                        dispatch(createApplicationFailure(data.message))
                    }
                    else {
                        dispatch(createApplicationSuccess(data.application))
                        // TODO: set template when creating the app???
                        dispatch(setApplicationTutorialTemplateUuid(data.application.uuid, templateUuid))
                        dispatch(push( '/dashboard/applications/' + data.application.uuid));
                    }
                },
                err => dispatch(createApplicationFailure(err.message)));
    }
}
