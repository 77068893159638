import {
    GET_DEPLOYMENT_STATS_REQUEST_SCHEDULED,
    GET_DEPLOYMENT_STATS_REQUESTED,
} from "../../actions/deployments";
import {LOGOUT_SUCCESS} from "../../actions/account";

const initialState = {
    // Dictionary of scheduled deployment stats requests, by deployment uuid. Example: { "dep1": true }
    isScheduledByDeploymentUuid: {},

}

const applicationStats = (state = initialState, action) => {
    switch (action.type) {
        // Single deployment stats api
        case GET_DEPLOYMENT_STATS_REQUEST_SCHEDULED:
            return {
                ...state,
                isScheduledByDeploymentUuid: {
                    ...state.isScheduledByDeploymentUuid,
                    [action.deploymentUuid]: true
                }
            }

        case GET_DEPLOYMENT_STATS_REQUESTED:
            return {
                ...state,
                isScheduledByDeploymentUuid: {
                    ...state.isScheduledByDeploymentUuid,
                    [action.deploymentUuid]: false
                }
            }


        case LOGOUT_SUCCESS:
            return {
                ...state,
                ...initialState
            }

        default:
            return state
    }
}

export default applicationStats
