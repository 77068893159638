import React from "react";
import { Redirect } from "react-router";
import ApplicationBreadcrumb from "../containers/dashboard/layout/breadcrumbs/ApplicationBreadcrumb";
import DeploymentBreadcrumb from "../containers/dashboard/layout/breadcrumbs/DeploymentBreadcrumb";
import InstanceBreadcrumb from "../containers/dashboard/layout/breadcrumbs/InstanceBreadcrumb";
import Loadable from "../utils/loadable";
import ConfigurationBreadcrumb from "../containers/dashboard/layout/breadcrumbs/ConfigurationBreadcrumb";


const routeConfig = [
    {
        path: "/",
        exact: true,
        component: Loadable(() => import("../containers/home/HomePage")),
        breadcrumb: null,
    },
    /*
    {
        path: "/",
        exact: true,
        component: Redirect,
        componentProps: {
            to: '/beta/signup'
        }
    },*/
    {
        path: "/login",
        component: Loadable(() => import("../containers/account/LoginPage")),
        exact: true,
    },
    {
        path:"/register",
        component: Loadable(() => import("../containers/account/RegisterPage")),
        exact: true,
    },
    {
        path:"/register/:invitationCode",
        component: Loadable(() => import("../containers/account/RegisterPage")),
        exact: true,
    },
    {
        path:"/beta/signup/:success?",
        component: Loadable(() => import("../containers/beta/BetaSignupPage")),
        exact: true,
    },
    {
        path:"/docs/api-reference",
        component: Loadable(() => import("../containers/docs/ApiReferencePage")),
        exact: true,
    },
    {
        path: "/dashboard",
        component: Loadable(() => import("../containers/dashboard/Dashboard")),
        breadcrumb: null,
        routes: [
            {
                path: '/dashboard',
                exact: true,
                component: Redirect,
                componentProps: {
                    to: '/dashboard/applications'
                }
            },
            {
                path: "/dashboard/applications",
                component: Loadable(() => import("../containers/dashboard/applications/ApplicationListPage")),
                exact: true,
            },
            {
                path: "/dashboard/applications/add",
                component: Loadable(() => import("../containers/dashboard/applications/CreateApplicationPage")),
            },
            {
                path: "/dashboard/applications/:applicationUuid/quickstart",
                component: Loadable(() => import("../containers/dashboard/applications/ApplicationQuickstartPage")),
                exact: true,
            },
            {
                path: '/dashboard/applications/:applicationUuid',
                component: Loadable(() => import("../containers/dashboard/applications/ApplicationDashboard")),
                breadcrumb: ApplicationBreadcrumb,
                routes: [
                    {
                        path: "/dashboard/applications/:applicationUuid",
                        component: Loadable(() => import("../containers/dashboard/applications/ApplicationInfoPage")),
                        exact: true,
                    },
                    {
                        path: "/dashboard/applications/:applicationUuid/deployments",
                        exact: true,
                        breadcrumb: "Deployments",
                        component: ({match}) => (<Redirect to={"/dashboard/applications/" + match.params["applicationUuid"]} />)
                    },
                    {
                        path: "/dashboard/applications/:applicationUuid/deployments/add",
                        exact: true,
                        breadcrumb: "New Deployment",
                        component: Loadable(() => import("../containers/dashboard/deployments/CreateDeploymentPage")),
                    },
                    {
                        path: "/dashboard/applications/:applicationUuid/deployments/:deploymentUuid",
                        exact: true,
                        breadcrumb: DeploymentBreadcrumb,
                        component: Loadable(() => import("../containers/dashboard/deployments/DeploymentInfoPage"))
                    },
                    {
                        path: "/dashboard/applications/:applicationUuid/deployments/:deploymentUuid/instances",
                        exact: true,
                        breadcrumb: "Deployments",
                        component: ({match}) => (<Redirect to={"/dashboard/applications/" + match.params["applicationUuid"] + "/deployments/" + match.params["deploymentUuid"]} />)
                    },
                    {
                        path: "/dashboard/applications/:applicationUuid/deployments/:deploymentUuid/instances/:instanceUuid",
                        exact: true,
                        breadcrumb: InstanceBreadcrumb,
                        component: Loadable(() => import("../containers/dashboard/instances/InstanceInfoPage"))
                    },
                    {
                        path: "/dashboard/applications/:applicationUuid/configurations",
                        exact: true,
                        breadcrumb: "Configuration History",
                        component: Loadable(() => import("../containers/dashboard/configurations/ConfigurationListPage"))
                    },
                    {
                        path: "/dashboard/applications/:applicationUuid/matchmaking",
                        exact: true,
                        breadcrumb: "Matchmaking",
                        component: Loadable(() => import("../containers/dashboard/matchmaking/MatchmakingPage")),
                    },
                    {
                        path: "/dashboard/applications/:applicationUuid/matchmaking/queues/add",
                        exact: true,
                        breadcrumb: "Matchmaking",
                        component: Loadable(() => import("../containers/dashboard/matchmaking/CreateMatchmakingQueuePage")),
                    },
                    {
                        path: "/dashboard/applications/:applicationUuid/matchmaking/queues/:queueUuid",
                        exact: true,
                        breadcrumb: "Matchmaking",
                        component: Loadable(() => import("../containers/dashboard/matchmaking/MatchmakingQueuePage")),
                    },
                    {
                        path: "/dashboard/applications/:applicationUuid/services/loadBalancing",
                        exact: true,
                        breadcrumb: "Service",
                        component: Loadable(() => import("../containers/dashboard/services/LoadBalancingPage")),
                    },
                    {
                        path: "/dashboard/applications/:applicationUuid/services/serviceDiscovery",
                        exact: true,
                        breadcrumb: "Service",
                        component: Loadable(() => import("../containers/dashboard/services/ServiceDiscoveryPage")),
                    },
                    {
                        path: "/dashboard/applications/:applicationUuid/services/pubSub",
                        exact: true,
                        breadcrumb: "Service",
                        component: Loadable(() => import("../containers/dashboard/services/PubSubPage")),
                    },
                    {
                        path: "/dashboard/applications/:applicationUuid/services/storage",
                        exact: true,
                        breadcrumb: "Service",
                        component: Loadable(() => import("../containers/dashboard/services/StoragePage")),
                    },
                    {
                        path: "/dashboard/applications/:applicationUuid/access",
                        exact: true,
                        breadcrumb: "Application Access",
                        component: Loadable(() => import("../containers/dashboard/access/ApplicationApiTokenListPage"))
                    },
                    {
                        path: "/dashboard/applications/:applicationUuid/versions",
                        exact: true,
                        breadcrumb: "Versions",
                        component: Loadable(() => import("../containers/dashboard/images/ImageListPage"))
                    },
                    {
                        path: "/dashboard/applications/:applicationUuid/configurations/latest",
                        exact: true,
                        breadcrumb: ConfigurationBreadcrumb,
                        component: Loadable(() => import("../containers/dashboard/configurations/ConfigurationPage"))
                    },
                    {
                        path: "/dashboard/applications/:applicationUuid/configurations/:configurationUuid",
                        exact: true,
                        breadcrumb: ConfigurationBreadcrumb,
                        component: Loadable(() => import("../containers/dashboard/configurations/ConfigurationPage"))
                    }
                ]
            }
        ]
    },
    {
        path: "/",
        breadcrumb: null,
        component: Loadable(() => import("../containers/notfound/NotFound"))
    }
]

export default routeConfig;