import React from "react";
import {connect} from "react-redux";

const mapStateToProps = function(state, ownProps){
    const applicationUuid = ownProps.match.params.applicationUuid;
    const configurations = state.data.configurations;

    return {
        configurationUuids: configurations.byApplicationUuid[applicationUuid],
        configurationsByUuid: configurations.byUuid,
    }
};

function DeploymentBreadcrumb({ match, configurationUuids, configurationsByUuid }){
    let configurationUuid = match.params.configurationUuid;

    if(configurationUuid){ // Has configuration uuid in location path
        return (<span>{configurationUuid.substr(0, 8)}</span>)
    } else if(configurationUuids) { // No location path but we already know default configuration uuid
        return (<span>{configurationUuids[0].substr(0, 8)}</span>)
    } else { // No location path but we are loading default configuration
        return (<span>Configuration</span>)
    }

}

export default connect(
    mapStateToProps
)(DeploymentBreadcrumb);
