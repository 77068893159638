import {
    GET_INSTANCE_LOGS_REQUEST_SCHEDULED,
    GET_INSTANCE_LOGS_REQUESTED,
} from "../../actions/instances";
import {LOGOUT_SUCCESS} from "../../actions/account";

const initialState = {
    // Dictionary of scheduled instance logs requests, by instance uuid. Example: {"ins1": true}
    isScheduledByInstanceUuid: {},

}

const instanceLogs = (state = initialState, action) => {
    switch (action.type) {

        // Single instance logs api
        case GET_INSTANCE_LOGS_REQUEST_SCHEDULED:
            return {
                ...state,
                isScheduledByInstanceUuid: {
                    ...state.isScheduledByInstanceUuid,
                    [action.instanceUuid]: true
                }
            }

        case GET_INSTANCE_LOGS_REQUESTED:
            return {
                ...state,
                isFetchingByInstanceUuid: {
                    ...state.isFetchingByInstanceUuid,
                    [action.instanceUuid]: true
                }
            }


        case LOGOUT_SUCCESS:
            return {
                ...state,
                ...initialState
            }

        default:
            return state
    }
}

export default instanceLogs
