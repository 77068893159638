import api from '../utils/api';
import {push} from "connected-react-router";

// Get application deployments actions
export const GET_APPLICATION_DEPLOYMENTS_REQUESTED = 'GET_APPLICATION_DEPLOYMENTS_REQUESTED';
const getApplicationDeploymentsRequest = (applicationUuid) => {
    return { type: GET_APPLICATION_DEPLOYMENTS_REQUESTED, applicationUuid }
};

export const GET_APPLICATION_DEPLOYMENTS_SUCCESS = 'GET_APPLICATION_DEPLOYMENTS_SUCCESS';
const getApplicationDeploymentsSuccess = (applicationUuid, deployments) => {
    return { type: GET_APPLICATION_DEPLOYMENTS_SUCCESS, applicationUuid, deployments }
};

export const GET_APPLICATION_DEPLOYMENTS_FAILURE = 'GET_APPLICATION_DEPLOYMENTS_FAILURE';
const getApplicationDeploymentsFailure = (applicationUuid, message) => {
    return { type: GET_APPLICATION_DEPLOYMENTS_FAILURE, applicationUuid, message }
};

export function getApplicationDeployments(applicationUuid){
    return (dispatch) => {
        dispatch(getApplicationDeploymentsRequest(applicationUuid));

        api.get('/api/applications/' + applicationUuid + '/deployments')
            .then(({ status, data }) => {
                    if(status !== 200) {
                        dispatch(getApplicationDeploymentsFailure(applicationUuid, data.message))
                    }
                    else {
                        dispatch(getApplicationDeploymentsSuccess(applicationUuid, data.deployments))
                    }
                },
                err => dispatch(getApplicationDeploymentsFailure(applicationUuid, err.message)));
    }
}

// Get single deployment by deployment uuid action
export const GET_DEPLOYMENT_REQUESTED = 'GET_DEPLOYMENT_REQUESTED';
const getDeploymentRequest = (applicationUuid, deploymentUuid) => {
    return { type: GET_DEPLOYMENT_REQUESTED, applicationUuid, deploymentUuid }
};

export const GET_DEPLOYMENT_SUCCESS = 'GET_DEPLOYMENT_SUCCESS';
const getDeploymentSuccess = (applicationUuid, deploymentUuid, deployment) => {
    return { type: GET_DEPLOYMENT_SUCCESS, applicationUuid, deploymentUuid, deployment }
};

export const GET_DEPLOYMENT_FAILURE = 'GET_DEPLOYMENT_FAILURE';
const getDeploymentFailure = (applicationUuid, deploymentUuid, message) => {
    return { type: GET_DEPLOYMENT_FAILURE, applicationUuid, deploymentUuid, message }
};

export function getDeployment(applicationUuid, deploymentUuid){
    return (dispatch) => {
        dispatch(getDeploymentRequest(applicationUuid, deploymentUuid));

        api.get('/api/applications/' + applicationUuid + '/deployments/' + deploymentUuid)
            .then(({ status, data }) => {
                    if(status !== 200) {
                        dispatch(getDeploymentFailure(applicationUuid, deploymentUuid, data.message))
                    }
                    else {
                        dispatch(getDeploymentSuccess(applicationUuid, deploymentUuid, data.deployment))
                    }
                },
                err => dispatch(getDeploymentFailure(applicationUuid, deploymentUuid, err.message)));
    }
}

// Get single deployment stats actions
export const GET_DEPLOYMENT_STATS_REQUESTED = 'GET_DEPLOYMENT_STATS_REQUESTED';
const getDeploymentStatsRequest = (applicationUuid, deploymentUuid) => {
    return { type: GET_DEPLOYMENT_STATS_REQUESTED, applicationUuid, deploymentUuid }
};

export const GET_DEPLOYMENT_STATS_SUCCESS = 'GET_DEPLOYMENT_STATS_SUCCESS';
const getDeploymentStatsSuccess = (applicationUuid, deploymentUuid, stats) => {
    return { type: GET_DEPLOYMENT_STATS_SUCCESS, applicationUuid, deploymentUuid, stats }
};

export const GET_DEPLOYMENT_STATS_FAILURE = 'GET_DEPLOYMENT_STATS_FAILURE';
const getDeploymentStatsFailure = (applicationUuid, deploymentUuid, message) => {
    return { type: GET_DEPLOYMENT_STATS_FAILURE, applicationUuid, deploymentUuid, message }
};

// Application stats scheduler actions
export const GET_DEPLOYMENT_STATS_REQUEST_SCHEDULED = 'GET_DEPLOYMENT_STATS_REQUEST_SCHEDULED';
const scheduleGetDeploymentStatsRequest = (applicationUuid, deploymentUuid) => {
    return { type: GET_DEPLOYMENT_STATS_REQUEST_SCHEDULED, applicationUuid, deploymentUuid }
};


export function scheduleGetDeploymentStats(applicationUuid, deploymentUuid, delay = 0){
    return (dispatch) => {
        dispatch(scheduleGetDeploymentStatsRequest(applicationUuid, deploymentUuid));

        setTimeout(() => {
            dispatch(getDeploymentStatsRequest(applicationUuid, deploymentUuid));

            api.get('/api/applications/' + applicationUuid + '/deployments/' + deploymentUuid + '/stats')
                .then(({status, data}) => {
                        if (status !== 200) {
                            dispatch(getDeploymentStatsFailure(applicationUuid, deploymentUuid, data.message))
                        } else {
                            dispatch(getDeploymentStatsSuccess(applicationUuid, deploymentUuid, data.stats))
                        }
                    },
                    err => dispatch(getDeploymentStatsFailure(applicationUuid, deploymentUuid, err.message)));
        }, delay);
    }
}

// Create new deployment actions
export const CREATE_DEPLOYMENT_REQUESTED = 'CREATE_DEPLOYMENT_REQUESTED';
const createDeploymentRequest = (applicationUuid, deployment) => {
    return { type: CREATE_DEPLOYMENT_REQUESTED, applicationUuid, deployment  }
};

export const CREATE_DEPLOYMENT_SUCCESS = 'CREATE_DEPLOYMENT_SUCCESS';
const createDeploymentSuccess = (applicationUuid, deployment) => {
    return { type: CREATE_DEPLOYMENT_SUCCESS, applicationUuid, deployment }
};

export const CREATE_DEPLOYMENT_FAILURE = 'CREATE_DEPLOYMENT_FAILURE';
const createDeploymentFailure = (applicationUuid, message, errors) => {
    return { type: CREATE_DEPLOYMENT_FAILURE, applicationUuid, message, errors }
};

export function createDeployment(applicationUuid, label, imageTag){
    return (dispatch) => {
        let newDeployment = { label: label, image_tag: imageTag };

        dispatch(createDeploymentRequest(applicationUuid, newDeployment));

        api.post('/api/applications/' + applicationUuid + '/deployments', newDeployment, true)
            .then(({ status, data }) => {
                    if(status !== 200) {
                        dispatch(createDeploymentFailure(applicationUuid, data.message, data.errors))
                    }
                    else {
                        dispatch(createDeploymentSuccess(applicationUuid, data.deployment))
                        dispatch(push( '/dashboard/applications/' + applicationUuid + '/deployments/' + data.deployment.uuid))
                    }
                },
                err => dispatch(createDeploymentFailure(applicationUuid, err.message)));
    }
}

// Delete deployment actions
export const DELETE_DEPLOYMENT_REQUESTED = 'DELETE_DEPLOYMENT_REQUESTED';
const deleteDeploymentRequest = (deployment) => {
    return { type: DELETE_DEPLOYMENT_REQUESTED, deployment  }
};

export const DELETE_DEPLOYMENT_SUCCESS = 'DELETE_DEPLOYMENT_SUCCESS';
const deleteDeploymentSuccess = (deployment) => {
    return { type: DELETE_DEPLOYMENT_SUCCESS, deployment }
};

export const DELETE_DEPLOYMENT_FAILURE = 'DELETE_DEPLOYMENT_FAILURE';
const deleteDeploymentFailure = (deployment, message, errors) => {
    return { type: DELETE_DEPLOYMENT_FAILURE, deployment, message, errors }
};

export function deleteDeployment(deployment){
    return (dispatch) => {
        dispatch(deleteDeploymentRequest(deployment));

        api.delete('/api/applications/' + deployment.application_uuid + '/deployments/' + deployment.uuid, true)
            .then(({ status, data }) => {
                    if(status !== 204) {
                        dispatch(deleteDeploymentFailure(deployment, data.message, data.errors))
                    }
                    else {
                        dispatch(deleteDeploymentSuccess(deployment))
                        dispatch(push( '/dashboard/applications/' + deployment.application_uuid))
                    }
                },
                err => dispatch(deleteDeploymentFailure(deployment, err.message)));
    }
}
