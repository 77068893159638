import {
    GET_INSTANCE_LOGS_REQUESTED,
    GET_INSTANCE_LOGS_SUCCESS,
    GET_INSTANCE_LOGS_FAILURE,
} from "../../actions/instances";
import {LOGOUT_SUCCESS} from "../../actions/account";

const initialState = {
    // Dictionary of currently fetching instance logs, by instance uuid. example: { "ins1": true }
    isFetchingByInstanceUuid: {},
}

const instanceLogs = (state = initialState, action) => {
    switch (action.type) {
        // Single deployment stats api
        case GET_INSTANCE_LOGS_REQUESTED:
            return {
                ...state,
                isFetchingByInstanceUuid: {
                    ...state.isFetchingByInstanceUuid,
                    [action.instanceUuid]: true
                }
            }

        case GET_INSTANCE_LOGS_SUCCESS:
            return {
                ...state,
                isScheduledByInstanceUuid: {
                    ...state.isScheduledByInstanceUuid,
                    [action.instanceUuid]: false
                },
                isFetchingByInstanceUuid: {
                    ...state.isFetchingByInstanceUuid,
                    [action.instanceUuid]: false
                }
            }

        case GET_INSTANCE_LOGS_FAILURE:
            return {
                ...state,
                isScheduledByInstanceUuid: {
                    ...state.isScheduledByInstanceUuid,
                    [action.instanceUuid]: false
                },
                isFetchingByInstanceUuid: {
                    ...state.isFetchingByInstanceUuid,
                    [action.instanceUuid]: false
                }
            }

        case LOGOUT_SUCCESS:
            return {
                ...state,
                ...initialState
            }

        default:
            return state
    }
}

export default instanceLogs
