export function saveAuthToken(authToken){
    if(typeof localStorage === 'undefined')
        return; // Server-side rendering

    localStorage.setItem('authToken', authToken);
}

export function hasAuthToken() {
    if(typeof localStorage === 'undefined')
        return false; // Server-side rendering

    return localStorage.getItem('authToken') !== null;
}

export function getAuthToken(){
    if(typeof localStorage === 'undefined')
        return null; // Server-side rendering

    return localStorage.getItem('authToken');
}

export function clearAuthToken() {
    if(typeof localStorage === 'undefined')
        return; // Server-side rendering

    localStorage.removeItem('authToken');
}
