import {
    GET_APPLICATION_IMAGES_REQUEST_SCHEDULED,
    GET_APPLICATION_IMAGES_REQUESTED,
} from "../../actions/images";

const initialState = {
    scheduled: false,
}

const applicationImagesScheduler = (state = initialState, action) => {
    switch (action.type) {
        case GET_APPLICATION_IMAGES_REQUEST_SCHEDULED:
            return {
                ...state,
                scheduled: true
            }
        case GET_APPLICATION_IMAGES_REQUESTED:
            return {
                ...state,
                scheduled: false
            }
        default:
            return state
    }
}

export default applicationImagesScheduler
