import {
    GET_APPLICATION_DEPLOYMENTS_REQUESTED,
    GET_APPLICATION_DEPLOYMENTS_SUCCESS,
    GET_APPLICATION_DEPLOYMENTS_FAILURE,
    GET_DEPLOYMENT_REQUESTED,
    GET_DEPLOYMENT_SUCCESS,
    GET_DEPLOYMENT_FAILURE,
    CREATE_DEPLOYMENT_REQUESTED,
    CREATE_DEPLOYMENT_SUCCESS,
    CREATE_DEPLOYMENT_FAILURE,
    DELETE_DEPLOYMENT_REQUESTED,
    DELETE_DEPLOYMENT_SUCCESS,
    DELETE_DEPLOYMENT_FAILURE,
} from "../../actions/deployments";

import {LOGOUT_SUCCESS} from "../../actions/account";

const initialState = {
    // Fetch list of application deployments - api state
    // Dictionary of currently fetching deployment lists, by application uuid. example: { "app1": true }
    isFetchingByApplicationUuid: {},
    // Error message
    fetchDeploymentsErrorMessage: null,

    // Fetch single application deployment by uuid - api state
    // Dictionary of currently fetching individual deployments, by deployment uuid. Example: {"dep1": true }
    isFetchingByDeploymentUuid: {},
    // Error message
    fetchDeploymentErrorMessage: null,

    // Create deployment - api state
    createDeploymentRequested: false,
    createDeploymentErrors: null,
    createDeploymentMessage: null,

    // Delete deployment - api state
    deleteDeploymentRequested: false,
    deleteDeploymentErrors: null,
    deleteDeploymentMessage: null,
}

const deployments = (state = initialState, action) => {
    switch (action.type) {
        // List deployments api
        case GET_APPLICATION_DEPLOYMENTS_REQUESTED:
            return {
                ...state,
                isFetchingByApplicationUuid: {
                    ...state.isFetchingByApplicationUuid,
                    [action.applicationUuid]: true
                },
            }

        case GET_APPLICATION_DEPLOYMENTS_SUCCESS:
            return {
                ...state,
                isFetchingByApplicationUuid: {
                    ...state.isFetchingByApplicationUuid,
                    [action.applicationUuid]: false
                },
                fetchDeploymentsErrorMessage: null
            }

        case GET_APPLICATION_DEPLOYMENTS_FAILURE:
            return {
                ...state,
                isFetchingByApplicationUuid: {
                    ...state.isFetchingByApplicationUuid,
                    [action.applicationUuid]: false
                },
                fetchDeploymentsErrorMessage: action.message
            }

        // Get deployment api
        case GET_DEPLOYMENT_REQUESTED:
            return {
                ...state,
                isFetchingByDeploymentUuid: {
                    ...state.isFetchingByDeploymentUuid,
                    [action.deploymentUuid]: true
                },
            }

        case GET_DEPLOYMENT_SUCCESS:
            return {
                ...state,
                isFetchingByDeploymentUuid: {
                    ...state.isFetchingByDeploymentUuid,
                    [action.deploymentUuid]: false
                },
                fetchDeploymentErrorMessage: null
            }

        case GET_DEPLOYMENT_FAILURE:
            return {
                ...state,
                isFetchingByDeploymentUuid: {
                    ...state.isFetchingByDeploymentUuid,
                    [action.deploymentUuid]: false
                },
                fetchDeploymentErrorMessage: action.message
            }

        // Create new deployment api
        case CREATE_DEPLOYMENT_REQUESTED:
            return {
                ...state,
                createDeploymentRequested: true,
                createDeploymentMessage: null,
                createDeploymentErrors: null,
            }

        case CREATE_DEPLOYMENT_SUCCESS:
            return {
                ...state,
                createDeploymentRequested: false,
                createDeploymentMessage: null,
                createDeploymentErrors: null,
            }

        case CREATE_DEPLOYMENT_FAILURE:
            return {
                ...state,
                createDeploymentRequested: false,
                createDeploymentMessage: action.message,
                createDeploymentErrors: action.errors,
            }

        // Delete new deployment api
        case DELETE_DEPLOYMENT_REQUESTED:
            return {
                ...state,
                deleteDeploymentRequested: true,
                deleteDeploymentMessage: null,
                deleteDeploymentErrors: null,
            }

        case DELETE_DEPLOYMENT_SUCCESS:
            return {
                ...state,
                deleteDeploymentRequested: false,
                deleteDeploymentMessage: null,
                deleteDeploymentErrors: null,
            }

        case DELETE_DEPLOYMENT_FAILURE:
            return {
                ...state,
                deleteDeploymentRequested: false,
                deleteDeploymentMessage: action.message,
                deleteDeploymentErrors: action.errors,
            }

        case LOGOUT_SUCCESS:
            return {
                ...state,
                ...initialState
            }

        default:
            return state
    }
}

export default deployments
