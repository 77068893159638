import api from '../utils/api';
import {push} from "connected-react-router";
import {addAlert} from "./alerts";

// Get application matchmaking quques actions
export const GET_APPLICATION_MATCHMAKING_QUEUES_REQUESTED = 'GET_APPLICATION_MATCHMAKING_QUEUES_REQUESTED';
const getApplicationMatchmakingQueuesRequest = (applicationUuid) => {
    return { type: GET_APPLICATION_MATCHMAKING_QUEUES_REQUESTED, applicationUuid }
};

export const GET_APPLICATION_MATCHMAKING_QUEUES_SUCCESS = 'GET_APPLICATION_MATCHMAKING_QUEUES_SUCCESS';
const getApplicationMatchmakingQueuesSuccess = (applicationUuid, queues) => {
    return { type: GET_APPLICATION_MATCHMAKING_QUEUES_SUCCESS, applicationUuid, queues }
};

export const GET_APPLICATION_MATCHMAKING_QUEUES_FAILURE = 'GET_APPLICATION_MATCHMAKING_QUEUES_FAILURE';
const getApplicationMatchmakingQueuesFailure = (applicationUuid, message) => {
    return { type: GET_APPLICATION_MATCHMAKING_QUEUES_FAILURE, applicationUuid, message }
};

export function getApplicationMatchmakingQueues(applicationUuid){
    return (dispatch) => {
        dispatch(getApplicationMatchmakingQueuesRequest(applicationUuid));

        api.get('/api/applications/' + applicationUuid + '/matchmaking/queues')
            .then(({ status, data }) => {
                    if(status !== 200) {
                        dispatch(getApplicationMatchmakingQueuesFailure(applicationUuid, data.message))
                    }
                    else {
                        dispatch(getApplicationMatchmakingQueuesSuccess(applicationUuid, data.queues))
                    }
                },
                err => dispatch(getApplicationMatchmakingQueuesFailure(applicationUuid, err.message)));
    }
}

// Get single matchmaking queue by queue uuid action
export const GET_MATCHMAKING_QUEUE_REQUESTED = 'GET_MATCHMAKING_QUEUE_REQUESTED';
const getMatchmakingQueueRequest = (applicationUuid, queueUuid) => {
    return { type: GET_MATCHMAKING_QUEUE_REQUESTED, applicationUuid, queueUuid }
};

export const GET_MATCHMAKING_QUEUE_SUCCESS = 'GET_MATCHMAKING_QUEUE_SUCCESS';
const getMatchmakingQueueSuccess = (applicationUuid, queueUuid, queue) => {
    return { type: GET_MATCHMAKING_QUEUE_SUCCESS, applicationUuid, queueUuid, queue }
};

export const GET_MATCHMAKING_QUEUE_FAILURE = 'GET_MATCHMAKING_QUEUE_FAILURE';
const getMatchmakingQueueFailure = (applicationUuid, queueUuid, message) => {
    return { type: GET_MATCHMAKING_QUEUE_FAILURE, applicationUuid, queueUuid, message }
};

export function getMatchmakingQueue(applicationUuid, queueUuid){
    return (dispatch) => {
        dispatch(getMatchmakingQueueRequest(applicationUuid, queueUuid));

        api.get('/api/applications/' + applicationUuid + '/deployments/' + queueUuid)
            .then(({ status, data }) => {
                    if(status !== 200) {
                        dispatch(getMatchmakingQueueFailure(applicationUuid, queueUuid, data.message))
                    }
                    else {
                        dispatch(getMatchmakingQueueSuccess(applicationUuid, queueUuid, data.queue))
                    }
                },
                err => dispatch(getMatchmakingQueueFailure(applicationUuid, queueUuid, err.message)));
    }
}

// Create new matchmaking queue actions
export const CREATE_MATCHMAKING_QUEUE_REQUESTED = 'CREATE_MATCHMAKING_QUEUE_REQUESTED';
const createMatchmakingQueueRequest = (applicationUuid, queue) => {
    return { type: CREATE_MATCHMAKING_QUEUE_REQUESTED, applicationUuid, queue  }
};

export const CREATE_MATCHMAKING_QUEUE_SUCCESS = 'CREATE_MATCHMAKING_QUEUE_SUCCESS';
const createMatchmakingQueueSuccess = (applicationUuid, queue) => {
    return { type: CREATE_MATCHMAKING_QUEUE_SUCCESS, applicationUuid, queue }
};

export const CREATE_MATCHMAKING_QUEUE_FAILURE = 'CREATE_MATCHMAKING_QUEUE_FAILURE';
const createMatchmakingQueueFailure = (applicationUuid, message, errors) => {
    return { type: CREATE_MATCHMAKING_QUEUE_FAILURE, applicationUuid, message, errors }
};

export function createMatchmakingQueue(applicationUuid, name, configuration){
    return (dispatch) => {
        let newMatchmakingQueue = { name: name, configuration: configuration };

        dispatch(createMatchmakingQueueRequest(applicationUuid, newMatchmakingQueue));

        api.post('/api/applications/' + applicationUuid + '/matchmaking/queues', newMatchmakingQueue, true)
            .then(({ status, data }) => {
                    if(status !== 200) {
                        dispatch(createMatchmakingQueueFailure(applicationUuid, data.message, data.errors))
                    }
                    else {
                        dispatch(createMatchmakingQueueSuccess(applicationUuid, data.queue))
                        dispatch(push( '/dashboard/applications/' + applicationUuid + '/matchmaking/queues/' + data.queue.uuid))
                        dispatch(addAlert("Queue " + data.queue.uuid.substr(0,8) + " created!", "success", true, 5000))
                    }
                },
                err => dispatch(createMatchmakingQueueFailure(applicationUuid, err.message)));
    }
}

// Update existing matchmaking queue actions
export const UPDATE_MATCHMAKING_QUEUE_REQUESTED = 'UPDATE_MATCHMAKING_QUEUE_REQUESTED';
const updateMatchmakingQueueRequest = (queue) => {
    return { type: UPDATE_MATCHMAKING_QUEUE_REQUESTED, queue  }
};

export const UPDATE_MATCHMAKING_QUEUE_SUCCESS = 'UPDATE_MATCHMAKING_QUEUE_SUCCESS';
const updateMatchmakingQueueSuccess = (queue) => {
    return { type: UPDATE_MATCHMAKING_QUEUE_SUCCESS, queue }
};

export const UPDATE_MATCHMAKING_QUEUE_FAILURE = 'UPDATE_MATCHMAKING_QUEUE_FAILURE';
const updateMatchmakingQueueFailure = (queue, message, errors) => {
    return { type: UPDATE_MATCHMAKING_QUEUE_FAILURE, queue, message, errors }
};

export function updateMatchmakingQueue(queue){
    return (dispatch) => {
        dispatch(updateMatchmakingQueueRequest(queue));

        api.put('/api/applications/' + queue.application_uuid + '/matchmaking/queues/' + queue.uuid, queue, true)
            .then(({ status, data }) => {
                    if(status !== 200) {
                        dispatch(updateMatchmakingQueueFailure(queue, data.message, data.errors))
                    }
                    else {
                        dispatch(updateMatchmakingQueueSuccess(queue));
                        dispatch(push( '/dashboard/applications/' + queue.application_uuid + '/matchmaking/queues/' + queue.uuid));
                        dispatch(addAlert("Queue " + queue.uuid.substr(0,8) + " updated!", "success", true, 5000))
                    }
                },
                err => dispatch(updateMatchmakingQueueFailure(queue, err.message)));
    }
}


// Delete matchmaking queue actions
export const DELETE_MATCHMAKING_QUEUE_REQUESTED = 'DELETE_MATCHMAKING_QUEUE_REQUESTED';
const deleteMatchmakingQueueRequest = (queue) => {
    return { type: DELETE_MATCHMAKING_QUEUE_REQUESTED, queue  }
};

export const DELETE_MATCHMAKING_QUEUE_SUCCESS = 'DELETE_MATCHMAKING_QUEUE_SUCCESS';
const deleteMatchmakingQueueSuccess = (queue) => {
    return { type: DELETE_MATCHMAKING_QUEUE_SUCCESS, queue }
};

export const DELETE_MATCHMAKING_QUEUE_FAILURE = 'DELETE_MATCHMAKING_QUEUE_FAILURE';
const deleteMatchmakingQueueFailure = (queue, message, errors) => {
    return { type: DELETE_MATCHMAKING_QUEUE_FAILURE, queue, message, errors }
};

export function deleteMatchmakingQueue(queue){
    return (dispatch) => {
        dispatch(deleteMatchmakingQueueRequest(queue));

        api.delete('/api/applications/' + queue.application_uuid + '/matchmaking/queues/' + queue.uuid, true)
            .then(({ status, data }) => {
                    if(status !== 204) {
                        dispatch(deleteMatchmakingQueueFailure(queue, data.message, data.errors))
                    }
                    else {
                        dispatch(deleteMatchmakingQueueSuccess(queue))
                        dispatch(push( '/dashboard/applications/' + queue.application_uuid + '/matchmaking'))
                    }
                },
                err => dispatch(deleteMatchmakingQueueFailure(queue, err.message)));
    }
}

// Get single matchmaking queue stats actions
export const GET_MATCHMAKING_QUEUE_STATS_REQUESTED = 'GET_MATCHMAKING_QUEUE_STATS_REQUESTED';
const getMatchmakingQueueStatsRequest = (applicationUuid, queueUuid) => {
    return { type: GET_MATCHMAKING_QUEUE_STATS_REQUESTED, applicationUuid, queueUuid }
};

export const GET_MATCHMAKING_QUEUE_STATS_SUCCESS = 'GET_MATCHMAKING_QUEUE_STATS_SUCCESS';
const getMatchmakingQueueStatsSuccess = (applicationUuid, queueUuid, stats) => {
    return { type: GET_MATCHMAKING_QUEUE_STATS_SUCCESS, applicationUuid, queueUuid, stats }
};

export const GET_MATCHMAKING_QUEUE_STATS_FAILURE = 'GET_MATCHMAKING_QUEUE_STATS_FAILURE';
const getMatchmakingQueueStatsFailure = (applicationUuid, queueUuid, message) => {
    return { type: GET_MATCHMAKING_QUEUE_STATS_FAILURE, applicationUuid, queueUuid, message }
};

// Matchmaking queue stats scheduler actions
export const GET_MATCHMAKING_QUEUE_STATS_REQUEST_SCHEDULED = 'GET_MATCHMAKING_QUEUE_STATS_REQUEST_SCHEDULED';
const scheduleGetMatchmakingQueueStatsRequest = (applicationUuid, queueUuid) => {
    return { type: GET_MATCHMAKING_QUEUE_STATS_REQUEST_SCHEDULED, applicationUuid, queueUuid }
};


export function scheduleGetMatchmakingQueueStats(applicationUuid, queueUuid, delay = 0){
    return (dispatch) => {
        dispatch(scheduleGetMatchmakingQueueStatsRequest(applicationUuid, queueUuid));

        setTimeout(() => {
            dispatch(getMatchmakingQueueStatsRequest(applicationUuid, queueUuid));

            api.get('/api/applications/' + applicationUuid + '/matchmaking/queues/' + queueUuid + '/stats')
                .then(({status, data}) => {
                        if (status !== 200) {
                            dispatch(getMatchmakingQueueStatsFailure(applicationUuid, queueUuid, data.message))
                        } else {
                            dispatch(getMatchmakingQueueStatsSuccess(applicationUuid, queueUuid, data.stats))
                        }
                    },
                    err => dispatch(getMatchmakingQueueStatsFailure(applicationUuid, queueUuid, err.message)));
        }, delay);
    }
}