import api from '../utils/api';
import {push} from "connected-react-router";

// Get application images actions
export const GET_APPLICATION_IMAGES_REQUESTED = 'GET_APPLICATION_IMAGES_REQUESTED';
const getApplicationImagesRequest = (applicationUuid) => {
    return { type: GET_APPLICATION_IMAGES_REQUESTED, applicationUuid }
};

export const GET_APPLICATION_IMAGES_SUCCESS = 'GET_APPLICATION_IMAGES_SUCCESS';
const getApplicationImagesSuccess = (applicationUuid, images) => {
    return { type: GET_APPLICATION_IMAGES_SUCCESS, applicationUuid, images }
};

export const GET_APPLICATION_IMAGES_FAILURE = 'GET_APPLICATION_IMAGES_FAILURE';
const getApplicationImagesFailure = (applicationUuid, message) => {
    return { type: GET_APPLICATION_IMAGES_FAILURE, applicationUuid, message }
};

export function getApplicationImages(applicationUuid){
    return (dispatch) => {
        dispatch(getApplicationImagesRequest(applicationUuid));

        api.get('/api/applications/' + applicationUuid + '/images')
            .then(({ status, data }) => {
                    if(status !== 200) {
                        dispatch(getApplicationImagesFailure(applicationUuid, data.message))
                    }
                    else {
                        dispatch(getApplicationImagesSuccess(applicationUuid, data.images))
                    }
                },
                err => dispatch(getApplicationImagesFailure(applicationUuid, err.message)));
    }
}


// Application image scheduler actions
export const GET_APPLICATION_IMAGES_REQUEST_SCHEDULED = 'GET_APPLICATION_IMAGES_REQUEST_SCHEDULED';
const scheduleGetApplicationImagesRequest = () => {
    return { type: GET_APPLICATION_IMAGES_REQUEST_SCHEDULED }
};

export function scheduleGetApplicationImages(applicationUuid, delay = 0){
    return (dispatch) => {
        dispatch(scheduleGetApplicationImagesRequest());
        setTimeout(() => dispatch(getApplicationImages(applicationUuid)), delay);
    }
}
