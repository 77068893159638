import {
    GET_APPLICATION_CONFIGURATIONS_REQUESTED,
    GET_APPLICATION_CONFIGURATIONS_SUCCESS,
    GET_APPLICATION_CONFIGURATIONS_FAILURE,
    CREATE_CONFIGURATION_REQUESTED,
    CREATE_CONFIGURATION_SUCCESS,
    CREATE_CONFIGURATION_FAILURE
} from "../../actions/configurations";

import {LOGOUT_SUCCESS} from "../../actions/account";

const initialState = {
    // Fetch list of application configurations - api state
    // Dictionary of currently fetching configuration lists, by application uuid. example: { "app1": true }
    isFetchingByApplicationUuid: {},
    // Error message
    fetchConfigurationsErrorMessage: null,

    // Create configuration - api state
    createConfigurationRequested: false,
    createConfigurationErrors: null,
    createConfigurationMessage: null
}

const configurations = (state = initialState, action) => {
    switch (action.type) {
        // List configurations api
        case GET_APPLICATION_CONFIGURATIONS_REQUESTED:
            return {
                ...state,
                isFetchingByApplicationUuid: {
                    ...state.isFetchingByApplicationUuid,
                    [action.applicationUuid]: true
                },
            }

        case GET_APPLICATION_CONFIGURATIONS_SUCCESS:
            return {
                ...state,
                isFetchingByApplicationUuid: {
                    ...state.isFetchingByApplicationUuid,
                    [action.applicationUuid]: false
                },
                fetchConfigurationsErrorMessage: null
            }

        case GET_APPLICATION_CONFIGURATIONS_FAILURE:
            return {
                ...state,
                isFetchingByApplicationUuid: {
                    ...state.isFetchingByApplicationUuid,
                    [action.applicationUuid]: false
                },
                fetchConfigurationsErrorMessage: action.message
            }

        // Create new configuration api
        case CREATE_CONFIGURATION_REQUESTED:
            return {
                ...state,
                createConfigurationRequested: true,
                createConfigurationMessage: null,
                createConfigurationErrors: null,
            }

        case CREATE_CONFIGURATION_SUCCESS:
            return {
                ...state,
                createConfigurationRequested: false,
                createConfigurationMessage: null,
                createConfigurationErrors: null,
            }

        case CREATE_CONFIGURATION_FAILURE:
            return {
                ...state,
                createConfigurationRequested: false,
                createConfigurationMessage: action.message,
                createConfigurationErrors: action.errors,
            }

        case LOGOUT_SUCCESS:
            return {
                ...state,
                ...initialState
            }

        default:
            return state
    }
}

export default configurations

