import api from '../utils/api';

// Get deployment instances
export const GET_DEPLOYMENT_INSTANCES_REQUESTED = 'GET_DEPLOYMENT_INSTANCES_REQUESTED';
const getDeploymentInstancesRequest = (applicationUuid, deploymentUuid) => {
    return { type: GET_DEPLOYMENT_INSTANCES_REQUESTED, applicationUuid, deploymentUuid }
};

export const GET_DEPLOYMENT_INSTANCES_SUCCESS = 'GET_DEPLOYMENT_INSTANCES_SUCCESS';
const getDeploymentInstancesSuccess = (applicationUuid, deploymentUuid, instances) => {
    return { type: GET_DEPLOYMENT_INSTANCES_SUCCESS, applicationUuid, deploymentUuid, instances }
};

export const GET_DEPLOYMENT_INSTANCES_FAILURE = 'GET_DEPLOYMENT_INSTANCES_FAILURE';
const getDeploymentInstancesFailure = (applicationUuid, deploymentUuid, message) => {
    return { type: GET_DEPLOYMENT_INSTANCES_FAILURE, applicationUuid, deploymentUuid, message }
};

// Deployment instances  scheduler actions
export const GET_DEPLOYMENT_INSTANCES_REQUEST_SCHEDULED = 'GET_DEPLOYMENT_INSTANCES_REQUEST_SCHEDULED';
const scheduleGetDeploymentInstancesRequest = (applicationUuid, deploymentUuid) => {
    return { type: GET_DEPLOYMENT_INSTANCES_REQUEST_SCHEDULED, applicationUuid, deploymentUuid }
};

export function getDeploymentInstances(applicationUuid, deploymentUuid){
    return (dispatch) => {
        dispatch(getDeploymentInstancesRequest(applicationUuid, deploymentUuid));

        api.get('/api/applications/' + applicationUuid + '/deployments/' + deploymentUuid + '/instances')
            .then(({ status, data }) => {
                    if(status !== 200) {
                        dispatch(getDeploymentInstancesFailure(applicationUuid, deploymentUuid, data.message))
                    }
                    else {
                        dispatch(getDeploymentInstancesSuccess(applicationUuid, deploymentUuid, data.instances))
                    }
                },
                err => dispatch(getDeploymentInstancesFailure(applicationUuid, deploymentUuid, err.message)));
    }
}

export function scheduleGetDeploymentInstances(applicationUuid, deploymentUuid, delay = 0){
    return (dispatch) => {
        dispatch(scheduleGetDeploymentInstancesRequest(applicationUuid, deploymentUuid, delay));
        setTimeout(() => {
            dispatch(getDeploymentInstances(applicationUuid, deploymentUuid))
        }, delay);
    }
}

// Get instance by uuid
export const GET_INSTANCE_REQUESTED = 'GET_INSTANCE_REQUESTED';
const getInstanceRequest = (applicationUuid, deploymentUuid, instanceUuid) => {
    return { type: GET_INSTANCE_REQUESTED, applicationUuid, deploymentUuid, instanceUuid }
};

export const GET_INSTANCE_SUCCESS = 'GET_INSTANCE_SUCCESS';
const getInstanceSuccess = (applicationUuid, deploymentUuid, instanceUuid, instance) => {
    return { type: GET_INSTANCE_SUCCESS, applicationUuid, deploymentUuid, instanceUuid, instance }
};

export const GET_INSTANCE_FAILURE = 'GET_INSTANCE_FAILURE';
const getInstanceFailure = (applicationUuid, deploymentUuid, instanceUuid, message) => {
    return { type: GET_DEPLOYMENT_INSTANCES_FAILURE, applicationUuid, deploymentUuid, instanceUuid, message }
};

export function getInstance(applicationUuid, deploymentUuid, instanceUuid){
    return (dispatch) => {
        dispatch(getInstanceRequest(applicationUuid, deploymentUuid, instanceUuid));

        api.get('/api/applications/' + applicationUuid + '/deployments/' + deploymentUuid + '/instances/' + instanceUuid)
            .then(({ status, data }) => {
                    if(status !== 200) {
                        dispatch(getInstanceFailure(applicationUuid, deploymentUuid, instanceUuid, data.message))
                    }
                    else {
                        dispatch(getInstanceSuccess(applicationUuid, deploymentUuid, instanceUuid, data.instance))
                    }
                },
                err => dispatch(getInstanceFailure(applicationUuid, deploymentUuid, instanceUuid, err.message)));
    }
}

// Get single instance logs preview actions
export const GET_INSTANCE_LOGS_REQUESTED = 'GET_INSTANCE_LOGS_REQUESTED';
const getInstanceLogsRequest = (applicationUuid, deploymentUuid, instanceUuid) => {
    return { type: GET_INSTANCE_LOGS_REQUESTED, applicationUuid, deploymentUuid, instanceUuid }
};

export const GET_INSTANCE_LOGS_SUCCESS = 'GET_INSTANCE_LOGS_SUCCESS';
const getInstanceLogsSuccess = (applicationUuid, deploymentUuid, instanceUuid, logs) => {
    return { type: GET_INSTANCE_LOGS_SUCCESS, applicationUuid, deploymentUuid, instanceUuid, logs }
};

export const GET_INSTANCE_LOGS_FAILURE = 'GET_INSTANCE_LOGS_FAILURE';
const getInstanceLogsFailure = (applicationUuid, deploymentUuid, instanceUuid, message) => {
    return { type: GET_INSTANCE_LOGS_FAILURE, applicationUuid, deploymentUuid, instanceUuid, message }
};

// Instance logs scheduler actions
export const GET_INSTANCE_LOGS_REQUEST_SCHEDULED = 'GET_INSTANCE_LOGS_REQUEST_SCHEDULED';
const scheduleGetInstanceLogsRequest = (applicationUuid, deploymentUuid, instanceUuid) => {
    return { type: GET_INSTANCE_LOGS_REQUEST_SCHEDULED, applicationUuid, deploymentUuid, instanceUuid }
};


export function scheduleGetInstanceLogs(applicationUuid, deploymentUuid, instanceUuid, delay = 0){
    return (dispatch) => {
        dispatch(scheduleGetInstanceLogsRequest(applicationUuid, deploymentUuid, instanceUuid));

        setTimeout(() => {
            dispatch(getInstanceLogsRequest(applicationUuid, deploymentUuid, instanceUuid));

            api.get('/api/applications/' + applicationUuid + '/deployments/' + deploymentUuid + '/instances/' + instanceUuid + '/logs/preview')
                .then(({status, data}) => {
                        if (status !== 200) {
                            dispatch(getInstanceLogsFailure(applicationUuid, deploymentUuid, instanceUuid, data.message))
                        } else {
                            dispatch(getInstanceLogsSuccess(applicationUuid, deploymentUuid, instanceUuid, data.log_lines))
                        }
                    },
                    err => dispatch(getInstanceLogsFailure(applicationUuid, deploymentUuid, instanceUuid, err.message)));
        }, delay);
    }
}


export function downloadInstanceLogs(applicationUuid, deploymentUuid, instanceUuid, delay = 0){
    return (dispatch) => {
        api.get('/api/applications/' + applicationUuid + '/deployments/' + deploymentUuid + '/instances/' + instanceUuid + '/logs')
            .then(({status, data}) => {
                    if(status !== 200){
                        console.log("Failed to download logs : " + data)
                        return;
                    }
                    window.location = data.url;
                },
                err => console.log(err));
    }
}
