import Loadable from "react-loadable";
import LoadingSpinner from "../components/loading/LoadingSpinner";

const LoadableComponent = (loader) => {
    return Loadable({
        loader: loader,
        loading: LoadingSpinner,
        timeout: 10000,
    });
}

export default LoadableComponent;
