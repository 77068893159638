import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from './reducers'
const thunk = require('redux-thunk').default;

function getComposeEnhancer(){
    if(typeof window === 'undefined' || !window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
        return compose
    }
    return window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
}


export default function configureStore(preloadedState, history) {
    const composeEnhancer = getComposeEnhancer()
    const store = createStore(
        createRootReducer(history),
        preloadedState,
        composeEnhancer(
            applyMiddleware(
                routerMiddleware(history),
                thunk
            ),
        ),
    )

    // Hot reloading
    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('./reducers', () => {
            store.replaceReducer(createRootReducer(history));
        });
    }

    return store
}
