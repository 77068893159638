import React from "react";

export default function LoadingSpinner() {
    return (
        <div className="row">
            <div className="col-lg-12" style={{"height": 220, "padding": 100}}>
                <div className="sk-spinner sk-spinner-wandering-cubes">
                    <div className="sk-cube1"></div>
                    <div className="sk-cube2"></div>
                </div>
            </div>
        </div>
    )
}
