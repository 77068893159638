import React from "react";
import {Route, Switch} from "react-router";
import PropTypes from "prop-types";

export default function Alert({ uuid, text, level, dismissable, onDismissed }){
    return (
        <div className="" style={{position: "fixed", top: 20, width:"100%", zIndex: 5000,}}>
            <div className="row">
                <div className="offset-1 col-10 offset-md-2 col-md-8">
                    <div className={["alert", "alert-"+level, "alert-transparent", (dismissable ? " alert-dismissable" : "")].join(" ")}>
                        {dismissable &&
                        <button aria-hidden="true" className="close" type="button" onClick={onDismissed}>×</button>
                        }
                        {text}
                    </div>
                </div>
            </div>
        </div>
    );
}


Alert.propTypes = {
    uuid: PropTypes.string,
    text: PropTypes.string,
    level: PropTypes.oneOf(['primary', 'secondary','success', 'danger', 'warning', 'info', 'light', 'dark']),
    dismissable: PropTypes.bool,
    onDismissed: PropTypes.func,
}
