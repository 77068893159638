import React from "react";
import {connect} from "react-redux";

const mapStateToProps = function(state, ownProps){
    const deployments = state.data.deployments;

    return {
        deployment: deployments.byUuid ? deployments.byUuid[ownProps.match.params.deploymentUuid] : null
    }
};

function DeploymentBreadcrumb({ deployment }){
    return (<span>{deployment ? deployment.label : "Deployment"}</span>)
}

export default connect(
    mapStateToProps
)(DeploymentBreadcrumb);
