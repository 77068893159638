import {
    GET_DEPLOYMENT_INSTANCES_REQUESTED,
    GET_DEPLOYMENT_INSTANCES_SUCCESS,
    GET_DEPLOYMENT_INSTANCES_FAILURE,
    GET_INSTANCE_REQUESTED,
    GET_INSTANCE_SUCCESS,
    GET_INSTANCE_FAILURE,
} from "../../actions/instances";

import {LOGOUT_SUCCESS} from "../../actions/account";

const initialState = {
    // Fetch list of deployment instances - api state
    // Dictionary of currently fetching instance lists, by deployment uuid. example: { "dep1": true }
    isFetchingByDeploymentUuid: {},
    // Error message
    fetchInstancesErrorMessage: null,

    // Fetch single deployment instance by uuid - api state
    // Dictionary of currently fetching individual instances, by instance uuid. Example: {"ins1": true }
    isFetchingByInstanceUuid: {},
    // Error message
    fetchInstanceErrorMessage: null,
}

const instances = (state = initialState, action) => {
    switch (action.type) {
        // List instances api
        case GET_DEPLOYMENT_INSTANCES_REQUESTED:
            return {
                ...state,
                isFetchingByDeploymentUuid: {
                    ...state.isFetchingByDeploymentUuid,
                    [action.deploymentUuid]: true
                },
            }

        case GET_DEPLOYMENT_INSTANCES_SUCCESS:
            return {
                ...state,
                isFetchingByDeploymentUuid: {
                    ...state.isFetchingByDeploymentUuid,
                    [action.deploymentUuid]: false
                },
                fetchInstancesErrorMessage: null
            }

        case GET_DEPLOYMENT_INSTANCES_FAILURE:
            return {
                ...state,
                isFetchingByDeploymentUuid: {
                    ...state.isFetchingByDeploymentUuid,
                    [action.deploymentUuid]: false
                },
                fetchInstancesErrorMessage: action.message
            }

        // Get single instance api
        case GET_INSTANCE_REQUESTED:
            return {
                ...state,
                isFetchingByInstanceUuid: {
                    ...state.isFetchingByInstanceUuid,
                    [action.instanceUuid]: true
                },
            }

        case GET_INSTANCE_SUCCESS:
            return {
                ...state,
                isFetchingByInstanceUuid: {
                    ...state.isFetchingByInstanceUuid,
                    [action.instanceUuid]: false
                },
                fetchInstanceErrorMessage: null
            }

        case GET_INSTANCE_FAILURE:
            return {
                ...state,
                isFetchingByInstanceUuid: {
                    ...state.isFetchingByInstanceUuid,
                    [action.instanceUuid]: false
                },
                fetchInstanceErrorMessage: action.message
            }

        case LOGOUT_SUCCESS:
            return {
                ...state,
                ...initialState
            }

        default:
            return state
    }
}

export default instances
