import api from "../utils/api";
import { push } from 'connected-react-router'

// Beta signup actions
export const BETA_SIGNUP_REQUESTED = 'BETA_SIGNUP_REQUESTED';
const betaSignupRequest = (email) => {
    return { type: BETA_SIGNUP_REQUESTED, email }
};

export const BETA_SIGNUP_SUCCESS = 'BETA_SIGNUP_SUCCESS';
const betaSignupSuccess = (email) => {
    return { type: BETA_SIGNUP_SUCCESS, email }
};

export const BETA_SIGNUP_FAILURE = 'BETA_SIGNUP_FAILURE';
const betaSignupFailure = (message, errors = null) => {
    return { type: BETA_SIGNUP_FAILURE, message, errors }
};

export function betaSignup(email){
    return (dispatch) => {
        dispatch(betaSignupRequest());

        api.post('/user/beta/signup', { email })
            .then(({ status, data }) => {
                    if(status !== 200) {
                        dispatch(betaSignupFailure(data.message, data.errors))
                    }
                    else {
                        dispatch(betaSignupSuccess(email))
                        dispatch(push('/beta/signup/success'))
                    }
                },
                err => dispatch(betaSignupFailure(err.message)));
    }
}
