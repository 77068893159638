import {
    GET_APPLICATION_IMAGES_SUCCESS,
} from "../../actions/images";

import {LOGOUT_SUCCESS} from '../../actions/account';

const initialState = {
    // List of image ids per application uuid. Example: {"app1": ["img1", "img2"]}
    byApplicationUuid: {},

    // image by id. Example: {"img1": { ... }, "img2": { ... }}
    byId: {},
}

const images = (state = initialState, action) => {
    switch (action.type) {
        case GET_APPLICATION_IMAGES_SUCCESS:
            // Map list of images into a list of ids: ["img1", "img2"]
            let applicationImageIds = action.images.map((image, i) => image.id);

            // Reduce list of images into a dictionary by id: {"img1": { ... }, "img2": { ... }}
            let applicationImageById = action.images.reduce((map, image) => {
                map[image.id] = image;
                return map;
            }, {});

            return {
                ...state,
                byApplicationUuid: {
                    ...state.byApplicationUuid,
                    [action.applicationUuid]: applicationImageIds,
                },
                byId: {
                    ...state.byId,
                    ...applicationImageById,
                }
            }

        case LOGOUT_SUCCESS:
            return {
                ...state,
                ...initialState
            }

        default:
            return state
    }
}

export default images