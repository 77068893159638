import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import configureStore from './configureStore'
import App from './App';
import {createBrowserHistory} from "history";
import {ConnectedRouter} from "connected-react-router";
import Loadable from 'react-loadable';

const history = createBrowserHistory()
const store = configureStore({}, history)

const Application = (
    <React.StrictMode>
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <App />
            </ConnectedRouter>
        </Provider>
    </React.StrictMode>
)

const root = document.getElementById('root');

function init() {
    if (root.hasChildNodes() === true) {
        Loadable.preloadReady().then(() => {
            ReactDOM.hydrate(Application, root);
        });
    } else {
        ReactDOM.render(Application, root);
    }
}

init();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
