import { combineReducers } from 'redux';
import applications  from './applications';
import applicationStats from "./applicationStats";
import deployments from "./deployments";
import deploymentStats from "./deploymentStats"
import instances from "./instances";
import instanceLogs from "./instanceLogs"
import configurations from "./configurations";
import images from "./images";
import tutorial from "./tutorial";
import tokens from "./tokens";
import login from "./login";
import matchmakingQueues from "./matchmakingQueues";
import matchmakingQueueStats from "./matchmakingQueueStats";

const dataReducer = (history) => combineReducers({
    applications,
    applicationStats,
    deployments,
    deploymentStats,
    instances,
    instanceLogs,
    configurations,
    images,
    tutorial,
    tokens,
    login,
    matchmakingQueues,
    matchmakingQueueStats,
})

export default dataReducer
