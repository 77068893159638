import React from "react";
import {connect} from "react-redux";

const mapStateToProps = function(state, ownProps){
    const applications = state.data.applications;

    return {
        application: applications.byUuid  ? applications.byUuid[ownProps.match.params.applicationUuid] : null
    }
};

function ApplicationBreadcrumb({ application }){
    return (<span>{application ? application.name : "Application"}</span>)
}

export default connect(
    mapStateToProps
)(ApplicationBreadcrumb);
