export default {
    "API_URL": process.env.REACT_APP_API_URL || 'https://api.fenrircloud.com/1.0',
    "CONNECT_URL": process.env.REACT_APP_CONNECT_URL || "https://connect.fenrircloud.com",
    "DOCKER_REGISTRY": process.env.REACT_APP_DOCKER_REGISTRY || "registry.fenrircloud.com",
    "APPLICATION_STATS_REFRESH_INTERVAL": 5000,
    "DEPLOYMENT_STATS_REFRESH_INTERVAL": 5000,
    "DEPLOYMENT_INSTANCES_REFRESH_INTERVAL": 3000,
    "MATCHMAKING_QUEUE_STATS_REFRESH_INTERVAL": 5000,
    "INSTANCE_LOGS_REFRESH_INTERVAL": 5000,
    "INSTANCE_LOGS_MAX_PREVIEW_LINES": 1,
    "APPLICATION_IMAGES_REFRESH_INTERVAL": 5000,
    "USER_MAX_DESIRED_INSTANCES": 50,
    "MAX_PORTS_PER_APPLICATION": 50,
    "OPEN_REGISTRATION_ENABLED": false,
    "API_SPEC_URL": "https://raw.githubusercontent.com/FenrirServer/openapi/master/fenrir-openapi-spec.yaml"
}
