import {
    BETA_SIGNUP_REQUESTED,
    BETA_SIGNUP_SUCCESS,
    BETA_SIGNUP_FAILURE,
} from "../../actions/beta";

const initialState = {
    isBetaSignupRequested: false, // true when beta signup request is sent
    message: null, // error message
    errors: null, // potential form errors
}

const login = (state = initialState, action) => {
    switch (action.type) {
        case BETA_SIGNUP_REQUESTED:
            return {
                ...state,
                isBetaSignupRequested: true,
                message: null,
                errors: null
            }
        case BETA_SIGNUP_FAILURE:
            return {
                ...state,
                isBetaSignupRequested: false,
                message: action.message,
                errors: action.errors
            }
        case BETA_SIGNUP_SUCCESS:
            return {
                ...state,
                isBetaSignupRequested: false,
                message: null,
                errors: null,
            }

        default:
            return state
    }
}

export default login