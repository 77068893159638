import api from '../utils/api';
import {push} from "connected-react-router";

// Get application tutorial actions
export const GET_APPLICATION_TUTORIAL_REQUESTED = 'GET_APPLICATION_TUTORIAL_REQUESTED';
const getApplicationTutorialRequest = (applicationUuid) => {
    return { type: GET_APPLICATION_TUTORIAL_REQUESTED, applicationUuid }
};

export const GET_APPLICATION_TUTORIAL_SUCCESS = 'GET_APPLICATION_TUTORIAL_SUCCESS';
const getApplicationTutorialSuccess = (applicationUuid, tutorial) => {
    return { type: GET_APPLICATION_TUTORIAL_SUCCESS, applicationUuid, tutorial }
};

export const GET_APPLICATION_TUTORIAL_FAILURE = 'GET_APPLICATION_TUTORIAL_FAILURE';
const getApplicationTutorialFailure = (applicationUuid, message) => {
    return { type: GET_APPLICATION_TUTORIAL_FAILURE, applicationUuid, message }
};

export function getApplicationTutorial(applicationUuid){
    return (dispatch) => {
        dispatch(getApplicationTutorialRequest(applicationUuid));

        api.get('/user/tutorial/' + applicationUuid)
            .then(({ status, data }) => {
                    if(status !== 200) {
                        dispatch(getApplicationTutorialFailure(applicationUuid, data.message))
                    }
                    else {
                        dispatch(getApplicationTutorialSuccess(applicationUuid, data.tutorial))
                    }
                },
                err => dispatch(getApplicationTutorialFailure(applicationUuid, err.message)));
    }
}

// Skip application tutorial actions
export const SKIP_APPLICATION_TUTORIAL_REQUESTED = 'SKIP_APPLICATION_TUTORIAL_REQUESTED';
const skipApplicationTutorialRequest = (applicationUuid) => {
    return { type: SKIP_APPLICATION_TUTORIAL_REQUESTED, applicationUuid }
};

export const SKIP_APPLICATION_TUTORIAL_SUCCESS = 'SKIP_APPLICATION_TUTORIAL_SUCCESS';
const skipApplicationTutorialSuccess = (applicationUuid) => {
    return { type: SKIP_APPLICATION_TUTORIAL_SUCCESS, applicationUuid }
};

export const SKIP_APPLICATION_TUTORIAL_FAILURE = 'SKIP_APPLICATION_TUTORIAL_FAILURE';
const skipApplicationTutorialFailure = (applicationUuid, message) => {
    return { type: SKIP_APPLICATION_TUTORIAL_FAILURE, applicationUuid, message }
};

export function skipApplicationTutorial(applicationUuid){
    return (dispatch) => {
        dispatch(skipApplicationTutorialRequest(applicationUuid));

        api.post('/user/tutorial/' + applicationUuid + '/skip')
            .then(({ status, data }) => {
                    if(status !== 200) {
                        dispatch(skipApplicationTutorialFailure(applicationUuid, data.message))
                    }
                    else {
                        dispatch(skipApplicationTutorialSuccess(applicationUuid))
                        dispatch(push( '/dashboard/applications/' + applicationUuid));
                    }
                },
                err => dispatch(skipApplicationTutorialFailure(applicationUuid, err.message)));
    }
}

// Set application tutorial template uuid
export const SET_APPLICATION_TUTORIAL_TEMPLATE_UUID_REQUESTED = 'SET_APPLICATION_TUTORIAL_TEMPLATE_UUID_REQUESTED';
const setApplicationTutorialTemplateUuidRequest = (applicationUuid, templateUuid) => {
    return { type: SET_APPLICATION_TUTORIAL_TEMPLATE_UUID_REQUESTED, applicationUuid, templateUuid }
};

export const SET_APPLICATION_TUTORIAL_TEMPLATE_UUID_SUCCESS = 'SET_APPLICATION_TUTORIAL_TEMPLATE_UUID_SUCCESS';
const setApplicationTutorialTemplateUuidSuccess = (applicationUuid) => {
    return { type: SET_APPLICATION_TUTORIAL_TEMPLATE_UUID_SUCCESS, applicationUuid }
};

export const SET_APPLICATION_TUTORIAL_TEMPLATE_UUID_FAILURE = 'SET_APPLICATION_TUTORIAL_TEMPLATE_UUID_FAILURE';
const setApplicationTutorialTemplateUuidFailure = (applicationUuid, message) => {
    return { type: SET_APPLICATION_TUTORIAL_TEMPLATE_UUID_FAILURE, applicationUuid, message }
};

export function setApplicationTutorialTemplateUuid(applicationUuid, templateUuid){
    return (dispatch) => {
        dispatch(setApplicationTutorialTemplateUuidRequest(applicationUuid, templateUuid));

        api.post('/user/tutorial/' + applicationUuid + '/set_template')
            .then(({ status, data }) => {
                    if(status !== 200) {
                        dispatch(setApplicationTutorialTemplateUuidFailure(applicationUuid, data.message))
                    }
                    else {
                        dispatch(setApplicationTutorialTemplateUuidSuccess(applicationUuid))
                    }
                },
                err => dispatch(setApplicationTutorialTemplateUuidFailure(applicationUuid, err.message)));
    }
}

