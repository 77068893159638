import {
    GET_API_TOKENS_REQUESTED,
    GET_API_TOKENS_SUCCESS,
    GET_API_TOKENS_FAILURE,
    CREATE_API_TOKEN_REQUESTED,
    CREATE_API_TOKEN_SUCCESS,
    CREATE_API_TOKEN_FAILURE,
    DELETE_API_TOKEN_REQUESTED,
    DELETE_API_TOKEN_SUCCESS,
    DELETE_API_TOKEN_FAILURE,
} from "../../actions/tokens";

import {LOGOUT_SUCCESS} from "../../actions/account";

const initialState = {
    // Get tokens api stats
    isFetching: false,
    // Error message
    fetchApiTokensErrorMessage: null,

    // Create token api state
    createApiTokenRequested: false,
    createApiTokenErrors: null,
    createApiTokenMessage: null,

    // Delete token api state
    deleteApiTokenRequested: false,
    deleteApiTokenErrors: null,
    deleteApiTokenMessage: null,
}

const tokens = (state = initialState, action) => {
    switch (action.type) {
        // List tokens api
        case GET_API_TOKENS_REQUESTED:
            return {
                ...state,
                isFetching: true
            }

        case GET_API_TOKENS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                fetchApiTokensErrorMessage: null
            }

        case GET_API_TOKENS_FAILURE:
            return {
                ...state,
                isFetching: false,
                fetchApiTokensErrorMessage: action.message
            }

        // Create new token api
        case CREATE_API_TOKEN_REQUESTED:
            return {
                ...state,
                createApiTokenRequested: true,
                createApiTokenMessage: null,
                createApiTokenErrors: null,
            }

        case CREATE_API_TOKEN_SUCCESS:
            return {
                ...state,
                createApiTokenRequested: false,
                createApiTokenMessage: null,
                createApiTokenErrors: null,
            }

        case CREATE_API_TOKEN_FAILURE:
            return {
                ...state,
                createApiTokenRequested: false,
                createApiTokenMessage: action.message,
                createApiTokenErrors: action.errors,
            }

        // Delete token api
        case DELETE_API_TOKEN_REQUESTED:
            return {
                ...state,
                deleteApiTokenRequested: true,
                deleteApiTokenMessage: null,
                deleteApiTokenErrors: null,
            }

        case DELETE_API_TOKEN_SUCCESS:
            return {
                ...state,
                deleteApiTokenRequested: false,
                deleteApiTokenMessage: null,
                deleteApiTokenErrors: null,
            }

        case DELETE_API_TOKEN_FAILURE:
            return {
                ...state,
                deleteApiTokenRequested: false,
                deleteApiTokenMessage: action.message,
                deleteApiTokenErrors: action.errors,
            }

        case LOGOUT_SUCCESS:
            return {
                ...state,
                ...initialState
            }

        default:
            return state
    }
}

export default tokens
