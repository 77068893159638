import config from "../config";
import fetch from "cross-fetch";
import {getAuthToken} from "./storage";


const encodeGetParams = p =>
    Object.entries(p).map(kv => kv.map(encodeURIComponent).join("=")).join("&");

const getAuthTokenHeader = function(){
    const authToken = getAuthToken();
    if(authToken === null){
        return {};
    }

    return {'Authorization': 'Bearer ' + authToken };
}


function send({ method, endpoint, params, data, dataTypeJson, responseTypeJson }) {
    let url = config.API_URL + endpoint;

    if(params){
        url += '?' + encodeGetParams(params);
    }

    // Build headers
    let headers = {}
    if(dataTypeJson)
    {
        headers["Content-Type"] = "application/json";
    }

    Object.assign(headers, getAuthTokenHeader()); // Add token header

    // Create request
    let request = { method, headers };

    // Add body
    if(data) {
        let body;
        if(dataTypeJson){
            // Json data
            body = JSON.stringify(data)
        }else {
            // Form data
            body = new FormData();
            for (const [key, value] of Object.entries(data)) {
                body.append(key, value);
            }
        }

        request["body"] = body;
    }

    return fetch(url, request)
        .then(response => Promise.all([response.status, response.text()]))
        //.then(x => new Promise(resolve => setTimeout(() => resolve(x), endpoint === '/api/applications' ? 5000 : 0))) // Uncomment to simulate slow api
        .then(function([status, text]) {
            if(responseTypeJson) {
                return { status: status, data: text.length > 0 ? JSON.parse(text) : {} }
            } else {
                return { status: status, data: text }
            }
        }).catch(function(error) {
            // log and rethrow
            console.log("Error while sending request: " + method + " " + url)
            console.log(error);
            throw error;
        });
}

function download({ method, url, params }){
    if(params){
        url += '?' + encodeGetParams(params);
    }
    let headers = {
        'Content-Type': 'text/plain',
    };
    Object.assign(headers, getAuthTokenHeader());

    let filename = url.substring(url.lastIndexOf('/')+1);
    
    fetch(url, {
        method: method,
        headers: headers,
    })
        .then((response) => response.blob())
        .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                filename,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
        });
}

export default {
    "get": function(endpoint, params = null, responseTypeJson = true) {
        return send({method: "GET", endpoint, params, responseTypeJson});
    },
    "post": function(endpoint, data = null, dataTypeJson = false, responseTypeJson = true) {
        return send({method: "POST", endpoint, data, dataTypeJson, responseTypeJson});
    },
    "delete": function(endpoint, params = null, responseTypeJson = true) {
        return send({method: "DELETE", endpoint, params, responseTypeJson});
    },
    "put": function(endpoint, data = null, dataTypeJson = false, responseTypeJson = true) {
        return send({method: "PUT", endpoint, data, dataTypeJson, responseTypeJson});
    },
    "patch": function(endpoint, data = null, dataTypeJson = false, responseTypeJson = true) {
        return send({method: "PATCH", endpoint, data, dataTypeJson, responseTypeJson});
    },
    "download": function(url, params = null) {
        return download({ method: "GET", url, params });
    }
}
