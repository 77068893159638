import {
    ADD_ALERT,
    REMOVE_ALERT,
} from "../../actions/alerts";

const initialState = {
    alerts: [],
}

const alerts = (state = initialState, action) => {
    switch (action.type) {
        case ADD_ALERT:
            return {
                ...state,
                alerts: [...state.alerts, { uuid: action.uuid, text: action.text, level: action.level, dismissable: action.dismissable }]
            }
        case REMOVE_ALERT:
            return {
                ...state,
                alerts: state.alerts.filter(alert => alert.uuid !== action.uuid)
            }
        default:
            return state
    }
}

export default alerts
