import {
    GET_APPLICATIONS_REQUESTED,
    GET_APPLICATIONS_SUCCESS,
    GET_APPLICATIONS_FAILURE,
    CREATE_APPLICATION_REQUESTED,
    CREATE_APPLICATION_SUCCESS,
    CREATE_APPLICATION_FAILURE
} from "../../actions/applications";
import {LOGOUT_SUCCESS} from "../../actions/account";

const initialState = {
    // Get applications api stats
    isFetching: false,
    fetchApplicationsErrorMessage: null,

    // Create application api state
    createApplicationRequested: false,
    createApplicationErrors: null,
    createApplicationMessage: null
}

const applications = (state = initialState, action) => {
    switch (action.type) {
        // List applications api
        case GET_APPLICATIONS_REQUESTED:
            return {
                ...state,
                isFetching: true,
                loadingStartTime: Date.now()
            }

        case GET_APPLICATIONS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                fetchApplicationsErrorMessage: null
            }

        case GET_APPLICATIONS_FAILURE:
            return {
                ...state,
                isFetching: false,
                fetchApplicationsErrorMessage: action.message
            }

        // Create new application api
        case CREATE_APPLICATION_REQUESTED:
            return {
                ...state,
                createApplicationRequested: true,
                createApplicationMessage: null,
                createApplicationErrors: null,
            }

        case CREATE_APPLICATION_SUCCESS:
            return {
                ...state,
                createApplicationRequested: false,
                createApplicationMessage: null,
                createApplicationErrors: null,
            }

        case CREATE_APPLICATION_FAILURE:
            return {
                ...state,
                createApplicationRequested: false,
                createApplicationMessage: action.message,
                createApplicationErrors: action.errors,
            }

        case LOGOUT_SUCCESS:
            return {
                ...state,
                ...initialState
            }

        default:
            return state
    }
}

export default applications
