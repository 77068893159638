import {
    GET_DEPLOYMENT_STATS_REQUESTED,
    GET_DEPLOYMENT_STATS_SUCCESS,
    GET_DEPLOYMENT_STATS_FAILURE,
} from "../../actions/deployments";
import {LOGOUT_SUCCESS} from "../../actions/account";

const initialState = {
    // Dictionary of currently fetching deployment stats, by deployment uuid. Example: { "dep1": true }
    isFetchingByDeploymentUuid: {},
}

const applicationStats = (state = initialState, action) => {
    switch (action.type) {
        // Single deployment stats api
        case GET_DEPLOYMENT_STATS_REQUESTED:
            return {
                ...state,
                isFetchingByDeploymentUuid: {
                    ...state.isFetchingByDeploymentUuid,
                    [action.deploymentUuid]: true
                }
            }

        case GET_DEPLOYMENT_STATS_SUCCESS:
            return {
                ...state,
                isFetchingByDeploymentUuid: {
                    ...state.isFetchingByDeploymentUuid,
                    [action.deploymentUuid]: false
                }
            }

        case GET_DEPLOYMENT_STATS_FAILURE:
            return {
                ...state,
                isFetchingByDeploymentUuid: {
                    ...state.isFetchingByDeploymentUuid,
                    [action.applicationUuid]: false
                }
            }

        case LOGOUT_SUCCESS:
            return {
                ...state,
                ...initialState
            }

        default:
            return state
    }
}

export default applicationStats
