import React, {Component} from "react";
import {connect} from "react-redux";
import {removeAlert} from "../../actions/alerts";
import Alert from "../../components/alerts/alert";

const mapStateToProps = (state, ownProps) => {
    const alerts = state.util.alerts;
    return {
        alerts: alerts.alerts
    }
};
const mapDispatchToProps = (dispatch, ownProps) => ({
    removeAlert: (uuid) => dispatch(removeAlert(uuid)),
})

class AlertList extends Component {
    render() {
        return (
            <div className="" style={{position: "fixed", top: 20, width: "100%", zIndex: 5000,}}>
                {this.props.alerts.map(alert => (
                   <Alert
                       uuid={alert.uuid}
                       text={alert.text}
                       dismissable={alert.dismissable}
                       level={alert.level}
                       onDismissed={() => this.props.removeAlert(alert.uuid)}
                   />
                ))}
            </div>
        );
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AlertList)

