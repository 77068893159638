import {
    GET_APPLICATION_DEPLOYMENTS_SUCCESS,
    GET_DEPLOYMENT_SUCCESS,
    CREATE_DEPLOYMENT_SUCCESS,
    DELETE_DEPLOYMENT_SUCCESS,
} from "../../actions/deployments";

import {LOGOUT_SUCCESS} from '../../actions/account';

const initialState = {
    // List of deployments uuids per application uuid. Example: {"app1": ["dep1", "dep2"]}
    byApplicationUuid: {},

    // Deployment by uuid. Example: {"dep1": { ... }, "dep2": { ... }}
    byUuid: {},
}

const deployments = (state = initialState, action) => {
    switch (action.type) {
        case GET_APPLICATION_DEPLOYMENTS_SUCCESS:
            // Map list of deployments into a list of uuids: ["depl1", "depl2"]
            let applicationDeploymentUuids = action.deployments.map((deployment, i) => deployment.uuid);

            // Reduce list of deployments into a dictionary by uuid: {"depl1": { ... }, "depl2": { ... }}
            let applicationDeploymentByUuid = action.deployments.reduce((map, deployment) => {
                map[deployment.uuid] = deployment;
                return map;
            }, {});

            return {
                ...state,
                byApplicationUuid: {
                    ...state.byApplicationUuid,
                    [action.applicationUuid]: applicationDeploymentUuids,
                },
                byUuid: {
                    ...state.byUuid,
                    ...applicationDeploymentByUuid,
                }
            }

        case GET_DEPLOYMENT_SUCCESS:
            return {
                ...state,
                byUuid: {
                    ...state.byUuid,
                    ...{ [action.deployment.uuid]: action.deployment },
                }
            }

        case CREATE_DEPLOYMENT_SUCCESS:
            return {
                ...state,
                byUuid: {
                    ...state.byUuid,
                    ...{ [action.deployment.uuid]: action.deployment },
                },
            }

        case DELETE_DEPLOYMENT_SUCCESS:
            return {
                ...state,
                byApplicationUuid: {
                    ...state.byApplicationUuid,
                    [action.deployment.application_uuid]: state.byApplicationUuid[action.deployment.application_uuid]
                        ? state.byApplicationUuid[action.deployment.application_uuid].filter(uuid => uuid !== action.deployment.uuid)
                        : undefined
                },
                byUuid: Object.keys(state.byUuid)
                    .filter(uuid => uuid !== action.deployment.uuid)
                    .reduce((obj, uuid) => {
                        obj[uuid] = state.byUuid[uuid];
                        return obj;
                    }, {}),
            }
        case LOGOUT_SUCCESS:
            return {
                ...state,
                ...initialState
            }

        default:
            return state
    }
}

export default deployments